import { useCallback, useMemo } from "react";
import GenericObject from "../../../../../../../typesAdditional/GenericObject";

interface Params {
    suggestions: any[];
    savedSessions: any[],
    currentRegistrationSessions: GenericObject[];
}

const groupAPrices = [80, 60, 60];
const groupBPrices = [250, 200];

const usePriceOffset = (params: Params) => {
    const { suggestions, savedSessions, currentRegistrationSessions } = params;

    const groupASessions = useMemo(() => {
        if (!suggestions || !suggestions.length) return [];

        return (savedSessions.find(g => g.group === 'A')?.sessions ?? []).concat(
            currentRegistrationSessions.filter(sess => suggestions.find(s => s.id === sess.id)?.location.course.name.toLowerCase() !== 'yoga').map(s => s.id)
        )
    }, [currentRegistrationSessions, suggestions, savedSessions])

    const groupBSessions = useMemo(() => {
        if (!suggestions || !suggestions.length) return [];

        return (savedSessions.find(g => g.group === 'B')?.sessions ?? []).concat(
            currentRegistrationSessions.filter(sess => suggestions.find(s => s.id === sess.id)?.location.course.name.toLowerCase() === 'yoga').map(s => s.id)
        )
    }, [currentRegistrationSessions, suggestions, savedSessions])

    const getSessionPriceWithOffset = useCallback((sessionId: string) => {
        if (!suggestions || !suggestions.length) return 0;

        const isAdult = suggestions.find(s => s.id === sessionId)?.location.course.courseCategoryId === 2;
        const isYoga = suggestions.find(s => s.id === sessionId)?.location.course.name.toLowerCase() === 'yoga';

        if (!isAdult) return suggestions.find(s => s.id === sessionId)?.prices.course.actual.price;

        if (!isYoga) {
            const index = groupASessions.findIndex((s: any) => s === sessionId);
            const realIndex = (index >= 0) ? index : groupASessions.length;

            return groupAPrices[Math.min(groupAPrices.length - 1, realIndex)] + ((suggestions.find(s => s.id === sessionId)?.location.townName === 'Petosino') ? 20 : 0);
        } else {
            const index = groupBSessions.findIndex((s: any) => s === sessionId);
            const realIndex = (index >= 0) ? index : groupBSessions.length;

            return groupBPrices[Math.min(groupBPrices.length - 1, realIndex)];
        }
    }, [groupASessions, groupBSessions, suggestions]);

    const getMultiSessionSaving = useCallback(() => {
        if (!suggestions || !suggestions.length) return 0;

        const toPay = currentRegistrationSessions.reduce((prev: any, curr: any) => {
            return prev + getSessionPriceWithOffset(curr.id);
        }, 0);

        const groupACourses = currentRegistrationSessions.filter(sess => suggestions.find(s => s.id === sess.id)?.location.course.name.toLowerCase() !== 'yoga').map(s => s.id);
        const groupBCourses = currentRegistrationSessions.filter(sess => suggestions.find(s => s.id === sess.id)?.location.course.name.toLowerCase() !== 'yoga').map(s => s.id);

        return (groupACourses.length * groupAPrices[0] + groupBCourses.length * groupBPrices[0]) - toPay;
    }, [currentRegistrationSessions, suggestions, getSessionPriceWithOffset]);

    const isUsingPriceOffsetHooks = useMemo(() => {
        return suggestions.some(s => s.location.course.courseCategoryId === 2);
    }, [suggestions]);

    return { getSessionPriceWithOffset, getMultiSessionSaving, isUsingPriceOffsetHooks }
}

export default usePriceOffset;