import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import { Divider, Link, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ButtonProps } from '@material-ui/core/Button';
import _ from 'lodash';
import moment from 'moment';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import api from '../../../../../../common/utils/api';

import detailsMap from './detailsMap';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import RegistrationsTable from '../../../../../admin/pages/registrationLocationPage/components/RegistrationsTable/RegistrationsTable';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    personId: string;
}

const PersonDataDialog = (props: Props) => {
    const { isOpen, onClose, personId } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<GenericObject | null>(null)

    useEffect(() => {
        if (!personId || !isOpen) return;

        setIsLoading(true);
        api.request('/admin/people/' + personId).then((res) => {
            setData(res);
            setIsLoading(false);
        })
    }, [isOpen, personId]);

    useEffect(() => {
        if (!isOpen) return;

        setData(null);
    }, [isOpen]);

    const { width } = useWindowSize();

    const isMobile = width < 700;

    const getTransformedAttribute = (section: any, attribute: any) => {
        const transformType = attribute.transform ?? null;
        const value = _.get(data, section.keyPrefix + '.' + attribute.key);

        if (transformType === 'date' && value) {
            return moment(value).format('D MMMM YYYY');
        }

        if (transformType === 'boolean') {
            return value ? 'sì' : 'no';
        }

        return value;
    };

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => onClose()
        }
    ];

    return (
        <>
            <CustomDialogWrapper fullScreen={isMobile} open={isOpen} onClose={onClose} title={'Dati anagrafici'} buttons={buttons} isLoading={isLoading} maxWidth='md' fullWidth contentProps={{}} >

                <TableContainer style={{ marginTop: '0px' }}>
                    <Table size='small'>
                        <TableBody>
                            {detailsMap.map(section => {
                                if ((data && _.get(data, section.keyPrefix))) {
                                    return (
                                        <>
                                            <TableRow>
                                                <TableCell colSpan={3}>
                                                    <Typography variant='h5' noWrap style={{ fontSize: '1.2em', marginBottom: '4px', display: 'flex', alignItems: 'center' }}>
                                                        <span>
                                                            {section.name}
                                                        </span>
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            {section.map.map(attribute => {
                                                const value = getTransformedAttribute(section, attribute);
                                                const isPhotoConsent = attribute.key === 'photoConsent';
                                                const isPhone = attribute.key === 'phone';
                                                const isAdminPhotoConsent = isPhotoConsent;

                                                if (!value) return null;
                                                if (isPhotoConsent) return null;

                                                return (
                                                    <TableRow style={(isPhotoConsent && value === 'no') ? { backgroundColor: '#f5a8a8' } : undefined}>
                                                        <TableCell style={{ fontWeight: 'bold' }}>{attribute.name}</TableCell>
                                                        <TableCell colSpan={(isAdminPhotoConsent || (isPhone)) ? 1 : 2}>{getTransformedAttribute(section, attribute)}</TableCell>
                                                        {(isPhone) && (
                                                            <TableCell style={{ textAlign: 'right' }}>
                                                                {/* @ts-ignore */}
                                                                <Button component={Link} target='_blank' href={"whatsapp://send?phone=" + _.get(data, section.keyPrefix + '.normalizedPhoneNumber')?.substring(1) ?? ''} color={'primary'} variant='outlined' size='small' style={{ backgroundColor: '#ffffff', textDecoration: 'none', color: '#128C7E', textAlign: 'center' }}>
                                                                    Contatta su WhatsApp
                                                                </Button>
                                                            </TableCell>
                                                        )}
                                                    </TableRow>
                                                );
                                            })}
                                            <TableRow style={{ height: '24px' }}>
                                                <TableCell colSpan={2} style={{ borderBottom: 'none' }} />
                                            </TableRow>
                                        </>
                                    );
                                }

                                return null;
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>


                {(data?.deletedRegistrations ?? []).length > 0 && (<>
                    <Divider />

                    <Typography variant='h5' noWrap style={{ fontSize: '1.45em', padding: '16px', display: 'flex', alignItems: 'center' }}>
                        Iscrizioni eliminate
                    </Typography>

                    <RegistrationsTable
                        registrations={(data?.deletedRegistrations ?? [])}
                        refreshRegistrations={() => undefined}
                        isLoading={false}
                        compactView
                        veryCompactView
                    />
                </>
                )}

            </CustomDialogWrapper>
        </>

    );
};

export default PersonDataDialog;
