import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ReduxState } from '../../../redux/types';
import { SuperUserRoute } from '../../../typesAdditional/Route';
import routes from '../routes';
import { Container, ExternalContainer } from './styled';

interface Props {
    appbarVisible: boolean;
}

const Router = (props: Props) => {
    const { appbarVisible } = props;

    const userInfo = useSelector((state: ReduxState) => state.user);
    const isUserLoggedIn = userInfo.id.length > 0;

    const isAdminHost = useMemo(() => {
        return window.location.host === 'admin.epuntos.it';
    }, []);

    const finalRoutes = useMemo(() => {
        const fallbackRoute: SuperUserRoute = {
            name: 'Fallback redirect',
            path: '*',
            showSidebar: false,
            canAuth: true,
            component: <Redirect to={isAdminHost ? '/' : '/admin'} />,
        };

        const routesWithSubroutes = routes.reduce((prev: SuperUserRoute[], current: SuperUserRoute) => {
            return [...prev, current, ...(current.subRoutes ?? [])];
        }, []);

        return [...routesWithSubroutes, fallbackRoute].map(route => {
            if (!isUserLoggedIn && route.requireAuth) {
                return (
                    <Route
                        path={(isAdminHost ? '' : '/admin') + route.path}
                        exact={route.exactPath}
                        component={() => <Redirect to={(isAdminHost ? '' : '/admin') + '/login'} />}
                        key={route.path}
                    />
                );
            }

            if (isUserLoggedIn && !route.canAuth) return null;
            if (route.needAdmin && !userInfo.administrator) return null;
            if ((route.path === '/summer-availability' || route.path === '/summer-schedule') && (!userInfo.allowSummerAvailabilities2024)) {
                return null;
            }

            if (route.path === '/summer-availability-admin' && userInfo.allowSummerAvailabilities2024) {
                return null;
            }

            if ((route.path === '/staff' || route.path === '/staff-winter') && (!userInfo.canManageStaff && userInfo.email !== 'ecappellini@epuntos.it')) {
                return null;
            }

            if (route.emailWhiteList && !route.emailWhiteList.includes(userInfo.email)) {
                return null;
            }

            return (
                <Route
                    path={(isAdminHost ? '' : '/admin') + route.path}
                    exact={route.exactPath}
                    component={() => {
                        // TODO: report to Sentry if component is undefined and there aren't subroutes
                        return route.component ?? null;
                    }}
                    key={route.path}
                />
            );
        }).filter((r: any) => r !== null);
    }, [isAdminHost, isUserLoggedIn, userInfo]);

    return (
        <ExternalContainer appbarVisible={appbarVisible}>
            <Container>
                <Switch>
                    {finalRoutes}
                </Switch>
            </Container>
        </ExternalContainer>
    );
};

export default Router;
