import { useCallback, useState } from 'react';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Badge from '@material-ui/core/Badge';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import DocumentsDetailDialog from '../../../../../public/pages/documentsPage/components/DocumentsDetailDialog/DocumentsDetailDialog';
import DetailsDialog from '../../../../../public/pages/registrationsListPage/components/DetailsDialog/DetailsDialog';
import PaymentsDialog from '../PaymentsDialog/PaymentsDialog';
import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import RegistrationNotesDialog from '../RegistrationNotesDialog/RegistrationNotesDialog';

interface Props {
    registrations: GenericObject[];
    refreshRegistrations: () => void;
    sessionsList?: string[];
    showSessions?: boolean;
    compactView?: boolean;
    veryCompactView?: boolean;
    isLoading?: boolean;
    singleSessionEvent?: boolean;
    waitingList?: boolean;
    softDeleted?: boolean;
    waGroupEnabled?: boolean;
}

const StyledBadge = styled(Badge) <{ customcolor: string }>`
    & .MuiBadge-colorPrimary { 
        background-color: ${props => props.customcolor} !important; 
    } 
`;

const RegistrationsTable = (props: Props) => {
    const { registrations, sessionsList, showSessions, compactView, veryCompactView, refreshRegistrations, isLoading, singleSessionEvent, waitingList, softDeleted, waGroupEnabled } = props;

    const [selectedRegistrationId, setSelectedRegistrationId] = useState<string | undefined>();
    const [selectedDocumentsRow, setSelectedDocumentsRow] = useState<any>({});
    const [detailsBaseUrl, setDetailsBaseUrl] = useState('');

    const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
    const [isDocumentsDialogOpen, setIsDocumetsDialogOpen] = useState(false);
    const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
    const [isNotesDialogOpen, setIsNotesDialogOpen] = useState(false);

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const confirmRegistration = useCallback((registration: any) => {
        showMessageDialog({
            title: 'Conferma iscrizione',
            message: (
                <>
                    <p style={{ margin: '0px', marginBottom: '6px' }}>
                        Sei sicuro di volere {waitingList ? 'confermare' : 'ripristinare'} l'iscrizione selezionata?
                    </p>
                    <p style={{ margin: '0px' }}>
                        {waitingList ? "L'iscrizione verrà rimossa dalla lista d'attesa, e verrà aggiunta all'elenco effettivo degli iscritti." : "L'iscrizione verrà aggiunta all'elenco effettivo degli iscritti."}
                    </p>
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: waitingList ? 'Conferma' : 'Ripristina',
                    action: () => {
                        api.request('/admin/courses/' + (registration.course.id) + '/locations/' + (registration.courseLocation.id) + '/registrations/' + registration.id + '/confirm', 'POST').then(() => {
                            snackbar.success('Iscrizione ' + (waitingList ? 'confermata' : 'ripristinata') + ' con successo!');
                            closeMessageDialog();
                            refreshRegistrations();
                        })
                    }
                }
            ]
        });
    }, [refreshRegistrations, closeMessageDialog, showMessageDialog, waitingList]);

    return (
        <>
            {isLoading ? (
                <Alert severity='info'>
                    Caricamento in corso...
                </Alert>
            ) : (
                <TableContainer component={Paper}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                {compactView ? (
                                    <>
                                        <TableCell>Corso</TableCell>
                                        <TableCell>Anno</TableCell>
                                        <TableCell>Partecipante</TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell style={{ width: '1px' }}>#</TableCell>
                                        {waGroupEnabled && (
                                            <TableCell style={{ width: '1px' }}>
                                                WA
                                            </TableCell>
                                        )}
                                        <TableCell>Cognome</TableCell>
                                        <TableCell>Nome</TableCell>
                                    </>
                                )}
                                <TableCell>Data di nascita</TableCell>
                                {showSessions && (
                                    (sessionsList ?? []).map((session: string) => {
                                        // const name = session.replace(/ /g, '').replace(/-/g, '\n');
                                        const name = session;
                                        return (
                                            <TableCell style={{ textAlign: 'center' }}>{singleSessionEvent ? 'Adesione' : name.toLowerCase()}</TableCell>
                                        );
                                    })
                                )}

                                {!veryCompactView && (
                                    <>
                                        {(waitingList || softDeleted) ? (
                                            <>
                                                <TableCell>Data iscrizione</TableCell>
                                            </>
                                        ) : (
                                            <>
                                                <TableCell style={{ width: '1px' }}>Costo</TableCell>

                                                {!veryCompactView && (
                                                    <TableCell style={{ width: '1px' }}>Documenti</TableCell>
                                                )}

                                                {!compactView && (
                                                    <TableCell style={{ width: '1px' }}>Note</TableCell>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}

                                {!veryCompactView && (
                                    <TableCell style={{ width: '1px' }}>Dettagli</TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {registrations.sort((r1: any, r2: any) => {
                                if (r1?.course?.season?.year !== r2?.course?.season?.year) {
                                    return r1?.course?.season?.year - r2?.course?.season?.year;
                                }

                                if (waitingList || softDeleted) {
                                    return r1.createdAt.localeCompare(r2.createdAt);
                                } else {
                                    return r1.person.surname.localeCompare(r2.person.surname) || r1.person.name.localeCompare(r2.person.name);
                                }

                            }).map((registration: any, idx: number) => {
                                const paidStatus = (parseFloat(registration.cost) < parseFloat(registration.paid)) ? 'overpaid' : (parseFloat(registration.cost) === parseFloat(registration.paid) ? 'pagato' : (
                                    parseFloat(registration.paid) === 0 ? 'da pagare' : 'pagato parzialmente'
                                ));

                                const paidStatusColor = (registration.isSeasonArchived || paidStatus === 'overpaid') ? '#e8f4fd' : (paidStatus === 'pagato' ? '#edf7ed' : (
                                    paidStatus === 'da pagare' ? '#fdeded' : '#fff4e5'
                                ));

                                const requiredDocuments = registration.documents.filter((d: any) => {
                                    return d.status !== 'ok_not_required'
                                }).length;

                                const missingDocuments = registration.documents.filter((d: any) => {
                                    return d.status !== 'ok' && d.status !== 'ok_not_required' && d.status !== 'reviewing' && d.status !== 'expiring';
                                }).length;

                                const uploadedDocuments = registration.documents.filter((d: any) => {
                                    return d.status === 'ok' || d.status === 'expiring';
                                }).length;

                                const documentsStatusColor = (uploadedDocuments === requiredDocuments) ? '#edf7ed' : (
                                    (missingDocuments === requiredDocuments) ? '#fdeded' : '#fff4e5'
                                );

                                return (
                                    <TableRow style={(!registration?.person?.photoConsent) ? { backgroundColor: '#f5a8a8' } : undefined}>
                                        {compactView ? (
                                            <>
                                                <TableCell>{registration.course.name} - {registration.courseLocation.townName} {registration.isWaitingList ? '(lista d\'attesa)' : (softDeleted ? '(iscrizioni cancellate)' : '')}</TableCell>
                                                <TableCell>{registration.course.season.year}/{registration.course.season.year + 1}</TableCell>
                                                <TableCell>{registration.person.surname} {registration.person.name}</TableCell>
                                            </>
                                        ) : (
                                            <>
                                                <TableCell>{idx + 1}</TableCell>
                                                {waGroupEnabled && (
                                                    <TableCell>
                                                        <Tooltip title={registration.waGroupInfo.desc}>
                                                            <StyledBadge color='primary' customcolor={registration.waGroupInfo.color} variant="dot">
                                                                <WhatsAppIcon />
                                                            </StyledBadge>
                                                        </Tooltip>
                                                    </TableCell>
                                                )}
                                                <TableCell>{registration.person.surname}</TableCell>
                                                <TableCell>{registration.person.name}</TableCell>
                                            </>
                                        )}

                                        <TableCell>{moment(registration.person.dateOfBirth).format('DD/MM/YYYY')}</TableCell>
                                        {showSessions && (
                                            (sessionsList ?? []).map((session: string) => {
                                                const key = session.replace(/ /g, '').replace(/-/g, '');

                                                return (
                                                    <TableCell style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>{singleSessionEvent ? (registration.sessions[key] ? 'sì' : 'no') : (registration.sessionsLabel[key])}{registration.sessionsFood[key] ? ' \n+ pasti' : ''}</TableCell>
                                                );
                                            })
                                        )}

                                        {!veryCompactView && (
                                            <>
                                                {(waitingList || softDeleted) ? (
                                                    <>
                                                        <TableCell>{registration.registrationDatetime}</TableCell>
                                                    </>
                                                ) : (
                                                    <>
                                                        <TableCell style={{ backgroundColor: paidStatusColor, cursor: veryCompactView ? undefined : 'pointer' }} onClick={() => {
                                                            if (veryCompactView) return;

                                                            if (registration.isSeasonArchived) {
                                                                showMessageDialog({
                                                                    title: 'Stagione archiviata',
                                                                    message: (
                                                                        <>
                                                                            <p style={{ margin: '0px', marginBottom: '8px' }}>
                                                                                La stagione "{registration.course.season.name}" è stata archiviata.
                                                                            </p>
                                                                            <p style={{ margin: '0px' }}>
                                                                                Non è più possibile visualizzare o modificare i pagamenti.
                                                                            </p>
                                                                        </>
                                                                    )
                                                                });
                                                            } else {
                                                                setSelectedRegistrationId(registration.id);
                                                                setIsPaymentDialogOpen(true);
                                                            }
                                                        }}>
                                                            {paidStatus === 'overpaid' ? (
                                                                <b>
                                                                    €{parseFloat(registration.cost).toFixed(2)}
                                                                </b>
                                                            ) : (
                                                                <>
                                                                    €{parseFloat(registration.cost).toFixed(2)}
                                                                </>
                                                            )}
                                                        </TableCell>

                                                        {!veryCompactView && (
                                                            <TableCell style={{ backgroundColor: documentsStatusColor, whiteSpace: 'nowrap', cursor: 'pointer' }} onClick={() => {
                                                                setSelectedDocumentsRow({ person: registration.person, documents: registration.documents });
                                                                setIsDocumetsDialogOpen(true);
                                                            }}>
                                                                {requiredDocuments - missingDocuments} di {requiredDocuments} caricat{(requiredDocuments - missingDocuments) === 1 ? 'o' : 'i'}
                                                            </TableCell>
                                                        )}

                                                        {!compactView && (
                                                            <TableCell style={{ whiteSpace: 'nowrap' }} >
                                                                <Button color='primary' variant={!!registration.plainNotes ? 'contained' : 'outlined'} style={{ width: '114px' }} onClick={() => {
                                                                    setSelectedRegistrationId(registration.id);
                                                                    setIsNotesDialogOpen(true);
                                                                }}>
                                                                    {!!registration.notes ? 'Visualizza' : 'Aggiungi'}
                                                                </Button>
                                                            </TableCell>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}

                                        {!veryCompactView && (
                                            <TableCell style={{ whiteSpace: 'nowrap', backgroundColor: '#ffffff' }}>
                                                <Button onClick={() => {
                                                    setSelectedRegistrationId(registration.id);
                                                    setDetailsBaseUrl('/admin/courses/' + registration.course.id + '/locations/' + registration.courseLocation.id + '/registrations');
                                                    setIsDetailsDialogOpen(true);
                                                }} color={'primary'}>
                                                    Visualizza dettagli
                                                </Button>

                                                {(waitingList || softDeleted) && (
                                                    <Button onClick={() => {
                                                        confirmRegistration(registration);
                                                    }} color={'primary'}>
                                                        {waitingList ? 'Conferma' : 'Ripristina'} iscrizione
                                                    </Button>
                                                )}
                                            </TableCell>
                                        )
                                        }

                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <DetailsDialog isOpen={isDetailsDialogOpen} onClose={() => setIsDetailsDialogOpen(false)} selectedRegistrationId={selectedRegistrationId} baseUrl={detailsBaseUrl} refreshRegistrations={refreshRegistrations} isAdmin={true} />
            <DocumentsDetailDialog open={isDocumentsDialogOpen} closeDialog={() => setIsDocumetsDialogOpen(false)} selectedRow={selectedDocumentsRow} isAdmin={true} refreshRegistrations={refreshRegistrations} />
            <PaymentsDialog open={isPaymentDialogOpen} closeDialog={() => setIsPaymentDialogOpen(false)} registrationId={selectedRegistrationId} refreshRegistrations={refreshRegistrations} />
            <RegistrationNotesDialog open={isNotesDialogOpen} closeDialog={() => setIsNotesDialogOpen(false)} refreshRegistrations={refreshRegistrations} registration={registrations.find((r => r.id === selectedRegistrationId))} />
        </>
    );
};

export default RegistrationsTable;
