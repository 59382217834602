import { useRef, useEffect, useState, useCallback } from 'react';

import { ButtonProps } from '@material-ui/core/Button';
import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import api from '../../../../../../common/utils/api';
import { v4 as uuidv4 } from 'uuid';
import { Divider, Typography, MenuItem, LinearProgress } from '@material-ui/core';
import 'moment/locale/it';
import AvailabilityTable from '../FindAvailabilityDialog/components/AvailabilityTable/AvailabilityTable';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import snackbar from '../../../../../../common/utils/snackbar';
import useLocalStorageListener from '../../../../../../common/hooks/useLocalStorageListener/useLocalStorageListener';

export interface Entry {
    day: number;
    dayTime: 'morning' | 'afternoon';
}

interface Props {
    open: boolean;
    closeDialog: () => void;
    mode: 'add' | 'edit' | 'replacement';
    selectedWeek?: GenericObject;
    weekId?: string;
    locationId?: string;
    trainerId?: string;
    refreshData: () => void;
    entriesToSelect: Entry[] | null;
    readOnly: boolean;
}

const AddInstructorDialog = (props: Props) => {
    const { open, closeDialog, mode, selectedWeek, weekId, locationId, trainerId, refreshData, entriesToSelect, readOnly } = props;

    const [isLoading1, setIsLoading1] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [isLoading3, setIsLoading3] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const isLoading = isLoading1 || isLoading2 || isLoading3 || isDeleting;

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const [data, setData] = useState<GenericObject | undefined>(undefined);
    const [simulationData, setSimulationData] = useState<GenericObject | undefined>(undefined);

    const [selectedEntries, setSelectedEntries] = useState<GenericObject[]>([]);
    const [isFirstResponsible, setIsFirstResponsible] = useState(false);
    const [automaticWage, setAutomaticWage] = useState(true);
    const [ignoreComputations, setIgnoreComputations] = useState(false);
    const [wage, setWage] = useState('0.00');
    const [notes, setNotes] = useState('');

    useLocalStorageListener({
        name: 'summerAvailabilityLastUpdated', callback: () => {
            refreshInternalData(true);
            refreshSimulationData(true);
        }
    });

    useEffect(() => {
        if (!open) return;

        setData(undefined);
        setSimulationData(undefined);
        setIsDeleting(false);
    }, [open]);

    useEffect(() => {
        console.log(selectedEntries);
    }, [selectedEntries]);

    const isEntrySelected = useCallback((day: number, dayTime: string) => {
        return !!selectedEntries.find(x => x.day === day && x.dayTime === dayTime);
    }, [selectedEntries]);

    const selectEntry = useCallback((day: number, dayTime: string, selected: boolean) => {
        if (selected) {
            setSelectedEntries(curr => {
                const entry = { ...(selectedEntries.find(x => x.day === day && x.dayTime === dayTime) ?? { day, dayTime }) };

                return [
                    ...curr.filter(x => !(x.day === day && x.dayTime === dayTime)),
                    entry
                ]
            });
        } else {
            setSelectedEntries(curr => curr.filter(x => !(x.day === day && x.dayTime === dayTime)));
        }
    }, [selectedEntries]);

    const apiRequestId1 = useRef('');
    const apiRequestId2 = useRef('');

    const refreshInternalData = useCallback((silent = false) => {
        if (!weekId || !locationId || !trainerId || !open) return;

        const currentRequestId = uuidv4();
        apiRequestId1.current = currentRequestId;
        if (!silent) setIsLoading1(true);

        api.request('/admin/summer_schedule/weeks/' + weekId + '/locations/' + locationId + '/trainers/' + trainerId).then(res => {
            if (currentRequestId !== apiRequestId1.current) return;

            setData(res);

            if (!silent) {
                setSelectedEntries(res.scheduleEntries.length > 0 ? res.scheduleEntries : res.instructor.availabilities.filter((x: any) => x.availability === 'yes' && x.otherLocations.length === 0).filter((x: any) => entriesToSelect === null || entriesToSelect.find((y: any) => x.day === y.day && x.dayTime === y.dayTime)).map((x: any) => {
                    return { day: x.day, dayTime: x.dayTime };
                }));
                setIsFirstResponsible(res.isResponsible);

                if (res.overrideWage) {
                    setAutomaticWage(false);
                    setWage(parseFloat(res.overrideWage).toFixed(2));
                    setNotes(res.notes);
                } else {
                    setAutomaticWage(true);
                    setWage('0.00');
                    setNotes('');
                }

                setIgnoreComputations(res.ignoreComputations);
            }

            if (!silent) setIsLoading1(false);
        })
    }, [weekId, locationId, open, trainerId, entriesToSelect]);

    useEffect(() => {
        refreshInternalData();
    }, [refreshInternalData]);

    const refreshSimulationData = useCallback((silent = false) => {
        if (!weekId || !locationId || !trainerId || !open) return;
        if (!automaticWage) return;

        const currentRequestId = uuidv4();
        apiRequestId2.current = currentRequestId;
        if (!silent) setIsLoading2(true);

        api.request('/admin/summer_schedule/weeks/' + weekId + '/locations/' + locationId + '/trainers/' + trainerId + '/simulate', 'POST', { entries: selectedEntries, isFirstResponsible, automaticWage, ignoreComputations, wage, notes }).then(res => {
            if (currentRequestId !== apiRequestId2.current) return;

            setSimulationData(res);
            if (!silent) setIsLoading2(false);
        })
    }, [weekId, locationId, open, trainerId, selectedEntries, isFirstResponsible, automaticWage, ignoreComputations, wage, notes])

    useEffect(() => {
        refreshSimulationData();
    }, [refreshSimulationData]);

    const saveData = useCallback((deleteEntires = false) => {
        setIsLoading3(true);

        api.request('/admin/summer_schedule/weeks/' + weekId + '/locations/' + locationId + '/trainers/' + trainerId + '', 'POST', { entries: deleteEntires ? [] : selectedEntries, isFirstResponsible, automaticWage, ignoreComputations, wage, notes }).then(res => {
            snackbar.success('Turni ' + (deleteEntires ? 'eliminati' : 'salvati') + ' con successo!');
            closeDialog();
            refreshData();
        }).finally(() => {
            setIsLoading3(false);
        })
    }, [weekId, locationId, trainerId, selectedEntries, isFirstResponsible, automaticWage, ignoreComputations, wage, refreshData, closeDialog, notes]);

    const deleteEntries = useCallback(() => {
        showMessageDialog({
            title: 'Elimina turni',
            message: (
                <>
                    <p style={{ margin: '0px' }}>
                        Sei sicuro di volere eliminare i turni della settimana corrente dell'istruttore selezionato?
                    </p>
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Conferma',
                    action: () => {
                        closeMessageDialog();

                        setIsDeleting(true);
                        saveData(true);
                    }
                }
            ]
        });
    }, [closeMessageDialog, showMessageDialog, setIsDeleting, saveData]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'secondary',
            onClick: () => closeDialog()
        },
        ...((mode === 'edit' && !readOnly) ? [{
            children: 'Elimina',
            color: 'secondary',
            onClick: () => deleteEntries()
        } as ButtonProps] : []),
        ...(!readOnly ? [{
            children: 'Salva',
            color: 'primary',
            onClick: () => saveData()
        } as ButtonProps] : []),
    ];

    const instructor = data?.instructor ?? {};

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={mode === 'add' ? 'Aggiungi istruttore' : (mode === 'edit' ? 'Modifica turni istruttore' : 'Aggiunta sostituzione')} buttons={buttons} isLoading={isLoading} maxWidth='md' fullWidth>
            {(!data || isDeleting) ? (
                <LinearProgress style={{ margin: '8px 0' }} />
            ) : (
                <>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography style={{ fontSize: '1.25em', flex: 1 }}>
                                {(instructor.name && instructor.surname) ? (instructor.name + ' ' + instructor.surname) : instructor.email}
                            </Typography>
                            {/*
                            <Typography style={{ fontSize: '1em', textAlign: 'left', paddingTop: '2px' }} color="textSecondary">
                                {(instructor.email) ? (instructor.email) : ''}
                            </Typography>
                            */}
                        </div>
                        {instructor.residence && (
                            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                <p style={{ margin: 0, flex: 1 }} />
                                <Typography style={{ fontSize: '1.25em', flex: 1, textAlign: 'right' }}>
                                    {instructor.residence}
                                </Typography>
                                {/*
                                        <Typography style={{ fontSize: '0.95em', textAlign: 'right', marginTop: '-2px' }} color="textSecondary">
                                            residenza
                                        </Typography>
                                        */}
                                <p style={{ margin: 0, flex: 1 }} />
                            </div>
                        )}
                    </div>
                    <AvailabilityTable selectedWeek={selectedWeek} instructor={instructor} isEntrySelected={isEntrySelected} selectEntry={selectEntry} readOnlyCheckbox={readOnly} />
                    {instructor.notes && (
                        <>
                            <p style={{ margin: '16px 12px' }}><b>Note:</b> {instructor.notes}</p>
                            <Divider />
                        </>
                    )}

                    {/*
                    <Typography style={{ fontSize: '1.35em' }}>
                        Calcolo compenso
                    </Typography>
                    */}

                    <div style={{ display: 'flex', marginTop: '16px' }}>
                        {instructor.responsibleEnabled && (
                            <CustomTextField
                                select
                                label={'Primo responsabile'}
                                value={isFirstResponsible ? 'yes' : 'no'}
                                onChange={(e: any) => {
                                    setIsFirstResponsible(e.target.value === 'yes');
                                }}
                                variant='outlined'
                                size='small'
                                fullWidth
                                style={{ marginRight: '5px' }}
                                inputProps={{ readOnly }}
                            >
                                <MenuItem value={'yes'} style={{ whiteSpace: 'break-spaces' }}>
                                    sì
                                </MenuItem>
                                <MenuItem value={'no'} style={{ whiteSpace: 'break-spaces' }}>
                                    no
                                </MenuItem>
                            </CustomTextField>
                        )}

                        <CustomTextField
                            select
                            label={'Calcolo automatico compenso'}
                            value={automaticWage ? 'yes' : 'no'}
                            onChange={(e: any) => {
                                setAutomaticWage(e.target.value === 'yes');
                            }}
                            variant='outlined'
                            size='small'
                            fullWidth
                            style={{ marginRight: '5px ' }}
                            inputProps={{ readOnly }}
                        >
                            <MenuItem value={'yes'} style={{ whiteSpace: 'break-spaces' }}>
                                sì
                            </MenuItem>
                            <MenuItem value={'no'} style={{ whiteSpace: 'break-spaces' }}>
                                no
                            </MenuItem>
                        </CustomTextField>

                        <CustomTextField
                            select
                            label={'Ignora contabilità'}
                            value={ignoreComputations ? 'yes' : 'no'}
                            onChange={(e: any) => {
                                setIgnoreComputations(e.target.value === 'yes');
                            }}
                            variant='outlined'
                            size='small'
                            fullWidth
                            style={{ marginRight: '5px ' }}
                            disabled={isLoading}
                        >
                            <MenuItem value={'yes'} style={{ whiteSpace: 'break-spaces' }}>
                                sì
                            </MenuItem>
                            <MenuItem value={'no'} style={{ whiteSpace: 'break-spaces' }}>
                                no
                            </MenuItem>
                        </CustomTextField>

                        <CustomTextField
                            label='Compenso settimanale'
                            variant='outlined'
                            size='small'
                            value={automaticWage ? ((simulationData?.wage ?? 0.0).toFixed(2)) : (wage === 'NaN' ? '0.00' : wage)}
                            keepMounted={true}
                            onChange={(e) => {
                                setWage(e.target.value);
                            }}
                            onBlur={() => {
                                setWage((val: any) => {
                                    return Math.max(((typeof val === 'string') ? parseFloat(val.replaceAll(',', '.')) : val), 0).toFixed(2);
                                });
                            }}
                            style={{ marginLeft: '5px' }}
                            inputProps={{ readOnly: (automaticWage || readOnly) }}
                        />

                    </div>

                    <CustomTextField
                        label={'Note'}
                        variant='outlined'
                        size='small'
                        value={notes}
                        keepMounted={true}
                        onChange={(e) => {
                            setNotes(e.target.value);
                        }}
                        rows={2}
                        multiline
                        disabled={isLoading}
                        inputProps={{ readOnly: readOnly }}
                    />

                    {!automaticWage && instructor.availabilities.some((x: any) => x.otherLocations.length > 0) && (
                        <Typography variant="caption" display="block" gutterBottom>
                            Nella settimana corrente <b>{instructor.name}</b> svolge dei turni anche su{' '}
                            <b>{(() => {
                                const allOtherLocations = instructor.availabilities.reduce((prev: any, curr: any) => {
                                    return [...new Set([...prev, ...curr.otherLocations])];
                                }, [])

                                return allOtherLocations.length > 1 ? (allOtherLocations.slice(0, -1).join(', ') + ' e ' + allOtherLocations.slice(-1)) : allOtherLocations[0];
                            })()}</b>.{' '}
                            Inserendo manualmente il compenso per la settimana, tieni in considerazione che questo deve includere anche i turni svolti su altri camp e paesi.
                        </Typography>
                    )}


                </>
            )}
        </CustomDialogWrapper >
    );
};

export default AddInstructorDialog;
