import { useCallback, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from '@material-ui/lab';

import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import GenericObject from '../../../../../../typesAdditional/GenericObject';
import LoadingSimulationAlert from '../../components/LoadingSimulationAlert/LoadingSimulationAlert';
import SimulationRecapAlert from '../../components/SimulationRecapAlert/SimulationRecapAlert';
import StepFooter from '../../components/StepFooter/StepFooter';
import useSimulateRegistration from '../../hooks/useSimulateRegistration/useSimulateRegistration';
import { GenericStepProps } from '../types';
import CardSessionsSelector from './components/CardsSessionsSelector/CardSessionsSelector';
import DiscountSelector from './components/DiscountSelector/DiscountSelector';
import Header from './components/Header/Header';
import SuggestionsDialog from './components/SuggestionsDialog/SuggestionsDialog';
import TableSessionsSelector from './components/TableSessionsSelector/TableSessionsSelector';
import useHandleSelect from './hooks/useHandleSelect';
import useInternalNextStep from './hooks/useInternalNextStep';
import useTownFromParams from './hooks/useTownFromParams';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import SpecialDietsV2Selector from './components/SpecialDietsV2Selector/SpecialDietsV2Selector';
import usePriceOffset from './hooks/usePriceOffset';
import useSortAndFilterSessions from '../../hooks/useSortAndFilterSessions/useSortAndFilterSessions';
import useSessionsMergeFilter from './hooks/useSessionsMergeFilter';
import useHybridHandleSelect from './hooks/useHybridHandleSelect';
import NotteAlMultisportDetails from './components/NotteAlMultisportDetails/NotteAlMultisportDetails';
import { MenuItem } from '@material-ui/core';
import snackbar from '../../../../../../common/utils/snackbar';

interface Props extends GenericStepProps {
    courseData: any;
    setSelectedLocationId: (locationId: string) => void;
    setSelectedSessions: (sessions: any) => void;
    setSuggestedSessions: (sessions: any) => void;
    initialSelectedLocationId: string;
    initialSelectedSessions: any[];
    questionResponse: string;
    setQuestionResponse: (res: string) => void;
}

const RegistrationStep6 = (props: Props) => {
    const { courseId, courseData, data, prevStep, nextStep, onChange, rawOnChange, gtmStepPush, setSelectedLocationId: externalSetSelectedLocationId, setSelectedSessions: externalSetSelectedSessions, setSuggestedSessions: externalSetSelectedSuggestedSessions, initialSelectedSessions, initialSelectedLocationId, preselectedLocation, questionResponse, setQuestionResponse, welfareData } = props;
    const { locationType, locations, sessionType, enableSpecialDiets, enableSpecialDietsV2 } = courseData;

    const [selectedLocationId, setSelectedLocationId] = useState(initialSelectedLocationId);

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingAutoSelectSessions, setIsLoadingAutoSelectSessions] = useState(false);

    const { canUserChangeLocation } = useTownFromParams({ locations, setSelectedLocationId });

    const residenceTownLegalId = data?.participant?.address?.town_legal_id ?? '';
    const schoolGrade = data?.participant?.schoolGrade ?? 0;
    const yearOfBirth = parseInt((data?.participant?.date_of_birth ?? '').substr(0, 4));

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const [isSuggestionsDialogOpen, setIsSuggestionsDialogOpen] = useState(false);
    const [selectedSessionsSuggestions, setSelectedSessionsSuggestions] = useState<GenericObject[]>([]);

    useEffect(() => {
        const campTown = (courseData?.locations?.find((l: any) => l.id === selectedLocationId) ?? preselectedLocation)?.town?.name;

        const analyticsData = {
            visitorLoggedIn: '1',
            summer_camp: courseData?.name ?? '',
            camp_town: campTown ?? '',
            student_country: 'Italy',
            student_city: data?.participant?.address?.town_name ?? '',
            student_zip: data?.participant?.address?.zip ?? '',
        };

        gtmStepPush(4, analyticsData);
    }, [gtmStepPush, courseData, selectedLocationId, data, preselectedLocation]);

    const {
        simulateRegistrationData,
        selectedSessions,
        setSelectedSessions,
        hasFoodService,
        getSessionsLabel,
        countSessions,
        countSessionsFoodService,
        countQueueSessions,
        isLoading: isSimulationLoading,
        isSelected,
        isFoodSelected,
        toPay,
        saving,
        additionalSaving
    } = useSimulateRegistration({ courseId, courseData, data, sessionType, selectedLocationId, setSelectedLocationId, initialSelectedSessions, welfareData, suggestedSessions: selectedSessionsSuggestions });

    const shouldShowSpecialDietsForm = (enableSpecialDiets || enableSpecialDietsV2) && (!hasFoodService || selectedSessions.some(s => s.foodService));

    useEffect(() => {
        if (!shouldShowSpecialDietsForm) {
            rawOnChange('special_diets', '');   // legacy special diets
            rawOnChange('specialDietsV2', []);
        }
    }, [rawOnChange, shouldShowSpecialDietsForm])

    const { width } = useWindowSize();

    const selectedLocation = locations.find((l: any) => l.id === selectedLocationId);

    useEffect(() => {
        if (locationType !== 'multi_location') {
            setSelectedLocationId(locations[0]?.id ?? '');
        }
    }, [locationType, locations]);

    const realSessions = useSessionsMergeFilter({
        simulateRegistrationData,
        selectedSessionsSuggestions,
        showAllLocationsSessions: courseData.showAllLocationsSessions,
        courseId: courseData.id
    });

    const { handleSelect, handleFoodSelect } = useHandleSelect({ selectedSessions, setSelectedSessions, sessions: realSessions });
    const { handleSelect: handleSelectSuggestions, handleFoodSelect: handleFoodSelectSuggestions } = useHandleSelect({ selectedSessions: selectedSessionsSuggestions, setSelectedSessions: setSelectedSessionsSuggestions });

    const { handleSelect: handleHybridSelect, handleFoodSelect: handleHybridFoodSelect } = useHybridHandleSelect({
        sessions: realSessions,
        selectedSessions,
        selectedSessionsSuggestions,
        selectedLocationId,
        setSelectedLocationId,
        handleSelect,
        handleFoodSelect,
        handleSelectSuggestions,
        handleFoodSelectSuggestions
    });

    const isSuggestionSelected = (sessionId: string) => {
        return !!selectedSessionsSuggestions.find(s => s.id === sessionId);
    };

    const internalPrevStep = useCallback(() => {
        externalSetSelectedLocationId(selectedLocationId);
        externalSetSelectedSessions(selectedSessions);

        if (prevStep) {
            prevStep();
        }
    }, [selectedLocationId, selectedSessions, externalSetSelectedLocationId, externalSetSelectedSessions, prevStep]);

    const internalNextStep = useInternalNextStep({
        courseId,
        selectedLocationId,
        selectedSessions,
        data,
        sessionType,
        countSessions,
        countQueueSessions,
        setIsLoading,
        externalSetSelectedLocationId,
        externalSetSelectedSessions,
        externalSetSelectedSuggestedSessions,
        nextStep,
        selectedSessionsSuggestions,
        allowEmptyRegistration: courseData.allowEmptyRegistration,
        simulateRegistrationData,
        welfareData
    });

    const pricesNotAvailable = realSessions?.some((session: any) => {
        return session?.prices?.course?.actual?.price <= 0;
    }) && courseId !== '7030abec-7234-4939-a154-336743ed9f92' && courseId !== 'b4721094-e785-11ef-8676-bc2411550d1f';

    const isLoadingReal = isLoading || isSimulationLoading;
    const isLoadingRealFinal = isLoadingReal || isLoadingAutoSelectSessions;

    const resetSuggestedSessions = useCallback(() => {
        setSelectedSessionsSuggestions(sess => {
            return sess.filter(s => realSessions.find((s2: any) => s.id === s2.id));
        });
    }, [realSessions]);

    const nextStepCheckSuggestions = useCallback(() => {
        const cardinali = ['ulteriore', 'secondo', 'terzo', 'quarto'];
        const nCardinale = cardinali[selectedSessions.length];

        if ((data?.discount_id ?? '') && ((simulateRegistrationData?.discounts?.list ?? []).find((x: any) => x.id === data?.discount_id)?.description ?? '').includes('Tenaris') && !((data?._additionals?.tenarisEmployeeCode ?? '').trim())) {
            snackbar.error('È necessario inserire il codice dipendente');
            return;
        }

        if ((simulateRegistrationData?.suggestions ?? []).length > 0 && selectedSessions.length > 0 && courseId !== '7030abec-7234-4939-a154-336743ed9f92' && courseId !== 'b4721094-e785-11ef-8676-bc2411550d1f' && courseId !== '1734f8ab-9625-4354-a0be-54452be3a8dc') {
            showMessageDialog({
                title: 'Sconto dedicato disponibile',
                message: (
                    <>
                        {(courseData.category.name.toLowerCase().includes('adult') ? 'Vuoi aggiungere un ulteriore corso' : 'Vuoi aggiungere un ' + nCardinale + ' giorno su un Comune diverso') + ' ed ottenere una scontistica dedicata?'}
                    </>
                ),
                actions: [
                    {
                        text: 'No',
                        action: () => {
                            closeMessageDialog();
                            internalNextStep();
                        }
                    },
                    {
                        text: 'Sì',
                        action: () => {
                            closeMessageDialog();
                            setIsSuggestionsDialogOpen(true);
                            resetSuggestedSessions();
                        }
                    }
                ]
            });
        } else {
            internalNextStep();
        }
    }, [courseId, selectedSessions, closeMessageDialog, courseData, internalNextStep, showMessageDialog, simulateRegistrationData, resetSuggestedSessions, data?._additionals?.tenarisEmployeeCode, data?.discount_id]);

    const questions = simulateRegistrationData.sessions.filter((s: any) => s.question).map((s: any) => s.question);
    const selectedAnswer = (questions.length > 0 && questionResponse === questions[0]) ? 'yes' : 'no';

    const handleChangeQuestion = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        if (value === 'yes') {
            setQuestionResponse(questions.length > 0 ? questions[0] : '');
        } else {
            setQuestionResponse('');
        }
    };

    const { getSessionPriceWithOffset } = usePriceOffset({
        suggestions: simulateRegistrationData?.suggestions ?? [],
        savedSessions: simulateRegistrationData?.suggestionsPriceGroups ?? [],
        currentRegistrationSessions: selectedSessionsSuggestions
    });

    useEffect(() => {
        const sessionsToRemove = simulateRegistrationData.sessions.filter((s: any) => !((!s.question || s.question === questionResponse) && questionResponse !== s.disabledQuestion));
        sessionsToRemove.forEach((s: any) => {
            if (isSelected(s.id)) {
                handleSelect(s.id, false);
            }
        });
    }, [simulateRegistrationData, isSelected, handleSelect, questionResponse]);

    const singleSessionEvent = (sessionType === 'single_event' && ((simulateRegistrationData?.discounts?.selected?.customPricing?.sessions ?? simulateRegistrationData.sessions ?? []).length === 1));

    const filteredSessions = useSortAndFilterSessions({
        sessions: realSessions,
        sessionType,
        questionResponse,
        schoolGrade,
        yearOfBirth,
    })

    const userCanChooseSessions = !(filteredSessions.length === 1 || filteredSessions.every(s => s.mandatory)) || hasFoodService;

    useEffect(() => {
        setIsLoadingAutoSelectSessions(true);

        // this timeout is needed to prevent to set in the state the sessions of the old locations, and so avoid the error from the backend 
        const timeout = setTimeout(() => {
            if ((!userCanChooseSessions || filteredSessions.length === 0) && !isLoadingReal) {
                filteredSessions.forEach(sess => !isSelected(sess.id) && handleSelect(sess.id, true));
            }

            setIsLoadingAutoSelectSessions(false);
        }, 100);

        return () => clearTimeout(timeout);
    }, [isSelected, userCanChooseSessions, filteredSessions, handleSelect, isLoadingReal])

    useEffect(() => {
        if (!selectedLocationId && locations.length > 0 && courseData?.showAllLocationsSessions) {
            setSelectedLocationId(locations[0].id);
        }
    }, [selectedLocationId, locations, courseData]);

    const SessionSelectorComponent = width > 700 ? TableSessionsSelector : CardSessionsSelector;

    const [scuolaPortieriSelect1, setScuolaPortieriSelect1] = useState('individuale');
    const [scuolaPortieriSelect2, setScuolaPortieriSelect2] = useState('5');

    useEffect(() => {
        if (courseId !== '42990ea4-76a0-11ef-aac5-4865ee14ab2a') return;

        if (scuolaPortieriSelect1 === 'individuale') {
            if (scuolaPortieriSelect2 === '5') {
                setSelectedSessions([{ id: 'a62d6fdb-76a0-11ef-aac5-4865ee14ab2a', foodService: false }]);
            } else if (scuolaPortieriSelect2 === '10') {
                setSelectedSessions([{ id: 'b06adb47-76a0-11ef-aac5-4865ee14ab2a', foodService: false }]);
            } else if (scuolaPortieriSelect2 === 'annuale') {
                setSelectedSessions([{ id: 'c12759a6-76a0-11ef-aac5-4865ee14ab2a', foodService: false }]);
            }
        } else if (scuolaPortieriSelect1 === 'gruppo') {
            if (scuolaPortieriSelect2 === '5') {
                setSelectedSessions([{ id: 'ced7746a-76a0-11ef-aac5-4865ee14ab2a', foodService: false }]);
            } else if (scuolaPortieriSelect2 === '10') {
                setSelectedSessions([{ id: 'e2a1d64b-76a0-11ef-aac5-4865ee14ab2a', foodService: false }]);
            } else if (scuolaPortieriSelect2 === 'annuale') {
                setSelectedSessions([{ id: 'ef95b23e-76a0-11ef-aac5-4865ee14ab2a', foodService: false }]);
            }
        }
    }, [courseId, scuolaPortieriSelect1, scuolaPortieriSelect2, setSelectedSessions]);

    return (
        <>
            <Header
                locations={locations}
                locationType={locationType}
                selectedLocationId={selectedLocationId}
                setSelectedLocationId={setSelectedLocationId}
                selectedLocation={selectedLocation}
                setSelectedSessions={setSelectedSessions}
                canUserChangeLocation={canUserChangeLocation}
                isPreselectedLocation={!!preselectedLocation || courseData?.showAllLocationsSessions}
            />

            {selectedLocationId && (
                <>
                    <DiscountSelector
                        discounts={simulateRegistrationData?.discounts}
                        data={data}
                        rawOnChange={rawOnChange}
                    />

                    {questions.length === 1 && (
                        <div style={{ marginBottom: '20px' }}>
                            <Typography variant='h4' style={{ fontSize: '1.4em', textAlign: 'center', margin: '8px', marginBottom: '10px' }}>
                                {questions[0]}
                            </Typography>

                            <FormControl component='fieldset'>
                                <RadioGroup aria-label='gender' name='question' value={selectedAnswer} onChange={handleChangeQuestion}>
                                    <FormControlLabel value='no' control={<Radio color='primary' />} label='No' />
                                    <FormControlLabel value='yes' control={<Radio color='primary' />} label='Sì' />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    )}

                    {questions.length > 1 && (
                        <div style={{ marginBottom: '20px' }}>
                            <FormControl component='fieldset'>
                                <RadioGroup aria-label='gender' name='question' value={questionResponse} onChange={(e) => setQuestionResponse((e.target as HTMLInputElement).value)}>
                                    {questions.map((q: string) => {
                                        return (
                                            <FormControlLabel value={q} control={<Radio color='primary' />} label={q} />
                                        )
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </div>
                    )}

                    {pricesNotAvailable && (
                        <Alert severity='info' style={{ paddingBottom: '0px', marginBottom: '24px' }}>
                            <AlertTitle>Quote in fase di definizione</AlertTitle>
                        </Alert>
                    )}

                    {selectedLocationId === '96572ecf-05d5-4171-9f44-5c5e92951a09' && (
                        <Alert severity='info' style={{ paddingBottom: '0px', marginBottom: '14px' }}>
                            <AlertTitle>Settimane 20-24 Giugno e 27 Giugno - 1 Luglio</AlertTitle>
                            <p style={{ marginTop: 0 }}>
                                Iscrizioni aperte solo a PRIMARIA E SECONDARIA
                            </p>
                            <AlertTitle>Settimane 4-8 e 11-15 luglio</AlertTitle>
                            <p style={{ marginTop: 0 }}>
                                Iscrizioni aperte solo all'INFANZIA
                            </p>
                        </Alert>
                    )}

                    {['f84361c3-6d02-4bcc-acc1-f35951719aed', '11848520-a8eb-496d-9cc7-ec64bf9d1f19', '9f606eee-2416-4018-bc27-89ccc10be8e5'].includes(selectedLocationId) && residenceTownLegalId === 'H176' && (
                        <Alert severity='info' style={{ paddingBottom: '0px', marginBottom: '14px' }}>
                            <AlertTitle>PER I RESIDENTI DI RANICA IL COMUNE ELARGIRÀ CONTRIBUTI DEDICATI</AlertTitle>
                            <p style={{ marginTop: 0 }}>
                                Le procedure di richiesta contributo residenti sono da presentare direttamente presso gli uffici comunali.
                            </p>
                        </Alert>
                    )}

                    {(selectedLocationId === '1db4d6c2-9cba-473c-baf8-900f8f684644' || selectedLocationId === '5051c61b-2640-4b1a-8af4-b726a50560f1' || selectedLocationId === '41dc512e-bd3e-4bf8-8fd1-6bed79d5eba1' || selectedLocationId === '732bf3b4-7052-427f-8be7-1b56e4491642' || selectedLocationId === '8af08b55-79b1-4524-977e-a96873291264' || selectedLocationId === '63ed6291-22cb-4cbd-a16c-d6b46be4d8c5') && (
                        <Alert severity='info' style={{ paddingBottom: '0px', marginBottom: '14px' }}>
                            {/* VILLA D'ADDA */}
                            <p style={{ marginTop: 0 }}>
                                Le iscrizioni sono aperte solo agli alunni della scuola "Sacro Cuore".
                            </p>
                        </Alert>
                    )}

                    {(selectedLocationId === '2bc64b3c-59ea-4efa-8f9f-40dd114e63ff') && (
                        <Alert severity='info' style={{ paddingBottom: '0px', marginBottom: '14px' }}>
                            {/* Posticipo Grumello del Piano */}
                            <p style={{ marginTop: 0 }}>
                                Le iscrizioni sono aperte solo agli alunni della <b>scuola primaria "Don Milani"</b>.
                            </p>
                        </Alert>
                    )}

                    {((selectedLocationId === '937dc6ff-18cc-496a-9dba-84cdf0b28165' || selectedLocationId === '7a18c5c8-c705-4099-b3f3-62677a30cb11') && filteredSessions.length === 0 && !isLoadingRealFinal) ? (
                        <Alert severity='warning' style={{ paddingBottom: '0px', marginBottom: '14px' }}>
                            <p style={{ marginTop: 0 }}>
                                La Scuola Calcio è aperta soltato ai nati negli anni 2016/2017/2018/2019/2020.
                            </p>
                        </Alert>
                    ) : (
                        <>
                            {(selectedLocationId === '768a093a-8d28-4803-a4fb-cd4745e98151' && !questionResponse) ? (
                                <Alert severity='warning' style={{ paddingBottom: '0px', marginBottom: '14px' }}>
                                    <p style={{ marginTop: 0 }}>
                                        Siamo spiacenti ma il servizio di Posticipo Sportivo è aperto solo agli iscritti della scuola dell'infanzia Papa Giovanni XXIII. Il nostro corso PPS è aperto a tutti in tanti altri comuni della bergamasca.
                                    </p>
                                </Alert>
                            ) : (
                                <>
                                    <>
                                        {courseId === '42990ea4-76a0-11ef-aac5-4865ee14ab2a' ? (
                                            <>
                                                <CustomTextField
                                                    select
                                                    label={'Tipologia allenamento'}
                                                    value={scuolaPortieriSelect1}
                                                    onChange={(e: any) => {
                                                        setScuolaPortieriSelect1(e.target.value);
                                                    }}
                                                    variant='outlined'
                                                    fullWidth
                                                    style={{ marginTop: '6px' }}
                                                >
                                                    <MenuItem value={'individuale'} style={{ whiteSpace: 'break-spaces' }}>
                                                        allenamento individuale
                                                    </MenuItem>

                                                    <MenuItem value={'gruppo'} style={{ whiteSpace: 'break-spaces' }}>
                                                        allenamento di gruppo
                                                    </MenuItem>
                                                </CustomTextField>

                                                <CustomTextField
                                                    select
                                                    label={'Pacchetto allenamenti'}
                                                    value={scuolaPortieriSelect2}
                                                    onChange={(e: any) => {
                                                        setScuolaPortieriSelect2(e.target.value);
                                                    }}
                                                    variant='outlined'
                                                    fullWidth
                                                    style={{ marginTop: '6px' }}
                                                >
                                                    <MenuItem value={'5'} style={{ whiteSpace: 'break-spaces' }}>
                                                        5 allenamenti
                                                    </MenuItem>

                                                    <MenuItem value={'10'} style={{ whiteSpace: 'break-spaces' }}>
                                                        10 allenamenti
                                                    </MenuItem>

                                                    <MenuItem value={'annuale'} style={{ whiteSpace: 'break-spaces' }}>
                                                        annuale (circa 30)
                                                    </MenuItem>
                                                </CustomTextField>
                                            </>
                                        ) : (
                                            <>
                                                {!singleSessionEvent && userCanChooseSessions && (
                                                    <Typography variant='h4' style={{ fontSize: '1.4em', textAlign: 'center', margin: '8px', marginBottom: '10px' }}>
                                                        Seleziona {(sessionType === 'single_event' || sessionType === 'weekly_events') ? 'i giorni a cui' : 'le settimane a cui'} desideri iscriverti
                                                    </Typography>
                                                )}

                                                <div style={{ marginBottom: '16px' }}>
                                                    <SessionSelectorComponent
                                                        courseId={courseId}
                                                        sessionType={sessionType}
                                                        hasFoodService={hasFoodService}
                                                        simulateRegistrationData={simulateRegistrationData}
                                                        isSelected={isSelected}
                                                        isFoodSelected={isFoodSelected}
                                                        handleSelect={handleHybridSelect}
                                                        handleFoodSelect={handleHybridFoodSelect}
                                                        questionResponse={questionResponse}
                                                        schoolGrade={schoolGrade}
                                                        yearOfBirth={yearOfBirth}
                                                        pricesNotAvailable={pricesNotAvailable}
                                                        singleSessionEvent={singleSessionEvent}
                                                        isSummer={(courseData?.season?.name ?? '').toLowerCase().includes('estiva')}
                                                        userCanChooseSessions={userCanChooseSessions}
                                                        showAllLocationsSessions={courseData?.showAllLocationsSessions}
                                                        tmpFixCorsiAdulti={courseData.category.name.toLowerCase().includes('adult')}
                                                        getSessionPriceWithOffset={getSessionPriceWithOffset}
                                                        selectedSessionsSuggestions={selectedSessionsSuggestions}
                                                    />
                                                </div>
                                            </>
                                        )}

                                    </>
                                </>
                            )}
                        </>
                    )}


                </>
            )
            }

            {
                enableSpecialDiets && shouldShowSpecialDietsForm && (
                    <CustomTextField
                        value={data.special_diets === undefined ? simulateRegistrationData?.savedRegistration?.specialDiets : undefined}
                        label='Intolleranze o diete speciali'
                        path={'special_diets'}
                        data={data}
                        dataOnChange={onChange}
                        variant='outlined'
                        minRows={3}
                        multiline
                        style={{ marginTop: '6px' }}
                        disabled={Object.keys(simulateRegistrationData).length === 1}
                    />
                )
            }

            {
                enableSpecialDietsV2 && shouldShowSpecialDietsForm && (
                    <SpecialDietsV2Selector
                        selectedSpecialDiets={(data.specialDietsV2 === undefined ? simulateRegistrationData?.savedRegistration?.specialDietsV2 : data.specialDietsV2) ?? []}
                        rawOnChange={rawOnChange}
                        disabled={Object.keys(simulateRegistrationData).length === 1}
                    />
                )
            }

            {
                selectedLocationId === '7d71e6f5-dcda-4319-92b3-43246c15a55f' && (
                    <Alert severity='info' style={{ paddingBottom: '0px', marginBottom: '14px' }}>
                        <p style={{ marginTop: 0 }}>
                            N.B. : la quota settimanale dei pasti, 30€ - 5 pasti, sarà addebitata con Rid dall'Opera Sant'Alessandro.
                        </p>
                    </Alert>
                )
            }

            {(courseId === '7030abec-7234-4939-a154-336743ed9f92' || courseId === 'b4721094-e785-11ef-8676-bc2411550d1f') && <NotteAlMultisportDetails data={data} rawOnChange={rawOnChange} />}

            <div style={{ marginTop: '16px' }}>
                {isLoadingRealFinal ? (
                    <LoadingSimulationAlert />
                ) : (
                    <>
                        {!selectedLocationId && (
                            <Alert severity='warning' style={{ paddingBottom: '0px' }}>
                                <AlertTitle>Seleziona paese</AlertTitle>
                                <p style={{ marginTop: '0px' }}>
                                    Seleziona il paese dove desideri iscriverti.
                                </p>
                            </Alert>
                        )}

                        {(selectedLocationId && !countSessions && !countQueueSessions && !(selectedLocationId === '768a093a-8d28-4803-a4fb-cd4745e98151' && !questionResponse) && !(singleSessionEvent && courseData.allowEmptyRegistration)) && (
                            <Alert severity='warning' style={{ paddingBottom: '0px' }}>
                                <AlertTitle>Seleziona {(sessionType === 'single_event' || sessionType === 'weekly_events') ? 'i giorni a cui' : 'le settimane a cui'} desideri iscriverti.</AlertTitle>
                                <p style={{ marginTop: '0px' }}>
                                    È obbligatorio selezionare almeno {(sessionType === 'single_event' || sessionType === 'weekly_events') ? 'un giorno' : 'una settimana'}.
                                </p>
                            </Alert>
                        )}

                        {(!!((countSessions + countQueueSessions) > 0 || (singleSessionEvent && courseData.allowEmptyRegistration))) && (
                            <SimulationRecapAlert
                                simulateRegistrationData={simulateRegistrationData}
                                countSessions={countSessions}
                                countSessionsFoodService={countSessionsFoodService}
                                countQueueSessions={countQueueSessions}
                                toPay={toPay}
                                saving={saving}
                                additionalSaving={additionalSaving}
                                getSessionsLabel={getSessionsLabel}
                                pricesNotAvailable={pricesNotAvailable}
                                allowEmptyRegistration={courseData.allowEmptyRegistration}
                            />
                        )}
                    </>
                )}

            </div>

            <StepFooter isLoading={isLoadingRealFinal} prevStep={internalPrevStep} nextStep={() => nextStepCheckSuggestions()} />

            <SuggestionsDialog
                courseId={courseId}
                open={isSuggestionsDialogOpen}
                onClose={() => setIsSuggestionsDialogOpen(false)}
                suggestions={((simulateRegistrationData?.suggestions ?? []).filter((s: any) => (s.isVisible || selectedSessionsSuggestions.find((s2: any) => s.id === s2.id)) && !realSessions.find((s2: any) => s.id === s2.id))).map((s: any) => { return { ...s, isSuggestion: true } })}
                sessionType={sessionType}
                isSelected={isSuggestionSelected}
                handleSelect={handleSelectSuggestions}
                handleFoodSelect={handleFoodSelectSuggestions}
                resetSessions={resetSuggestedSessions}
                nextStep={internalNextStep}
                questionResponse={questionResponse}
                schoolGrade={schoolGrade}
                tmpFixCorsiAdulti={courseData.category.name.toLowerCase().includes('adult')}
                getSessionPriceWithOffset={getSessionPriceWithOffset}
                selectedSessions={selectedSessionsSuggestions}
            />
        </>
    );
};

export default RegistrationStep6;
