import { Dispatch, SetStateAction, useCallback } from 'react';

import useGenericErrorDialog from '../../../../../../../common/hooks/useGenericErrorDialog/useGenericErrorDialog';
import { useMessageDialog } from '../../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../../../../common/utils/api';
import GenericObject from '../../../../../../../typesAdditional/GenericObject';
import * as Sentry from '@sentry/react';

interface Params {
    courseId: string;
    selectedLocationId: string;
    selectedSessions: GenericObject[];
    data: GenericObject;
    welfareData: any;
    sessionType: string;
    countSessions: number;
    countQueueSessions: number;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    externalSetSelectedLocationId: (locationId: string) => void;
    externalSetSelectedSessions: (sessions: any) => void;
    externalSetSelectedSuggestedSessions: (sessions: any) => void;
    nextStep: () => void;
    selectedSessionsSuggestions: GenericObject[];
    allowEmptyRegistration: boolean;
    simulateRegistrationData: any;
}

const useInternalNextStep = (params: Params) => {
    const { courseId, selectedLocationId, selectedSessions, data, welfareData, sessionType, countSessions, countQueueSessions, setIsLoading, externalSetSelectedLocationId, externalSetSelectedSessions, externalSetSelectedSuggestedSessions, nextStep, selectedSessionsSuggestions, allowEmptyRegistration, simulateRegistrationData } = params;

    const [showMessageDialog] = useMessageDialog();
    const showGenericErrorDialog = useGenericErrorDialog();

    return useCallback(() => {
        if (!selectedLocationId) {
            showMessageDialog({
                title: 'Paese non selezionato',
                message: 'È necessario selezionare il paese d\'iscrizione.'
            });
        } else if ((countSessions + countQueueSessions) === 0 && !allowEmptyRegistration) {
            showMessageDialog({
                title: (sessionType === 'single_event' || sessionType === 'weekly_events') ? 'Giorni non selezionati' : 'Settimane non selezionate',
                message: 'È necessario selezionare ' + ((sessionType === 'single_event' || sessionType === 'weekly_events') ? 'i giorni a cui' : 'le settimane a cui') + ' desideri iscriverti.'
            });
        } else if (simulateRegistrationData?.isWelfareRegistration && simulateRegistrationData?.welfareStatus?.status !== 'ok') {
            const status = simulateRegistrationData?.welfareStatus?.status;

            if (status === 'welfare_amount_not_reached') {
                showMessageDialog({
                    title: 'Credito welfare non completamente utilizzato',
                    message: 'Per finalizzare l\'iscrizione, devi utilizzare tutto il tuo credito welfare.'
                });
            } else if (status === 'welfare_amount_exceeded') {
                showMessageDialog({
                    title: 'Credito welfare superato',
                    message: 'Non puoi effettuare un\'iscrizione per un importo superiore al tuo credito welfare.'
                });
            } else {
                Sentry.captureMessage('Invalid welfare status: ' + status);
            }


        } else if ((courseId === '7030abec-7234-4939-a154-336743ed9f92' || courseId === 'b4721094-e785-11ef-8676-bc2411550d1f') &&
            (data?._additionals?.ritiratoDaGenitore === undefined || data?._additionals?.oraRitiro === undefined || data?._additionals?.consensoFoto === undefined ||
                (!data?._additionals?.ritiratoDaGenitore && ((data?._additionals?.cognomePersonaRitiro ?? '') === '' || (data?._additionals?.nomePersonaRitiro ?? '') === '' || (data?._additionals?.codiceFiscalePersonaRitiro ?? '') === ''))
            )
        ) {
            showMessageDialog({
                title: 'Campi non compilati',
                message: 'Per proseguire è necessario compilare tutti i campi.'
            });
        } else {
            setIsLoading(true);

            const requestData = {
                ...data,
                locationId: selectedLocationId,
                sessions: selectedSessions,
                suggestedSessions: selectedSessionsSuggestions,
                documents: [],
                consents: [],
                welfareData
            };

            api.request('/courses/' + courseId + '/registration/simulate', 'POST', requestData).then(() => {
                externalSetSelectedLocationId(selectedLocationId);
                externalSetSelectedSessions(selectedSessions);
                externalSetSelectedSuggestedSessions(selectedSessionsSuggestions);
                nextStep();
            }).catch((res) => {
                showGenericErrorDialog(res);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [simulateRegistrationData, welfareData, allowEmptyRegistration, externalSetSelectedSuggestedSessions, selectedSessionsSuggestions, data, nextStep, sessionType, showGenericErrorDialog, showMessageDialog, courseId, selectedLocationId, countSessions, countQueueSessions, selectedSessions, externalSetSelectedLocationId, externalSetSelectedSessions, setIsLoading]);
};

export default useInternalNextStep;
