import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import useSearchParams from '../../../../common/hooks/useSearchParams/useSearchParams';
import { ReduxState } from '../../../../redux/types';
import Paper from '@material-ui/core/Paper';
import { useWindowSize } from 'react-use';
import Button from '@material-ui/core/Button';

const RegistrationLanding = () => {
    const { id: courseId } = useParams<{ id: string }>();
    const { width } = useWindowSize();

    const { isIframe } = useSelector((state: ReduxState) => state.routing);
    const { town: paramTownLegalId } = useSearchParams();

    const history = useHistory();

    const iframeSuffix = isIframe ? '&iframe' : '';

    useEffect(() => {
        if (courseId !== '7030abec-7234-4939-a154-336743ed9f92' && courseId !== 'b4721094-e785-11ef-8676-bc2411550d1f') {
            history.push('/courses/' + courseId + '/registration?town=' + paramTownLegalId + iframeSuffix);
        }
    }, [history, courseId, paramTownLegalId, iframeSuffix]);

    return (
        <div>
            {courseId === '7030abec-7234-4939-a154-336743ed9f92' && (
                <div>
                    <Paper elevation={isIframe ? 0 : 3} style={{ padding: '8px', margin: width > 700 ? '6px 24px' : '2px', textAlign: 'center' }}>
                        <p style={{ fontSize: '1.3em', marginTop: '4px', marginBottom: 0 }}>
                            🤗 Ricordiamo che <b>l'evento è riservato <u>esclusivamente</u> agli associati</b> di educazione.sport <b>che:</b>
                        </p>
                        <p style={{ fontSize: '1.3em', margin: '0px auto', width: 'fit-content', textAlign: 'left' }}>
                            <ul>
                                <li>hanno effettuato un'iscrizione <b>ai camp estivi nell'Estate 2023</b> o</li>
                                <li>sono iscritti <b>ai corsi annuali 2023/2024.</b></li>
                            </ul>
                        </p>
                        <p style={{ fontSize: '1.3em', marginTop: '12px', marginBottom: 0 }}>
                            ➡️ <b>Il numero di iscrizioni massime</b> è di <b>25</b> partecipanti.
                        </p>
                        <p style={{ fontSize: '1.3em', marginTop: '18px', marginBottom: 0 }}>
                            📩 Al termine della compilazione <b>riceverai una mail</b> con il numero di posizione assegnato.
                        </p>
                        <p style={{ fontSize: '1.3em', marginTop: '18px', marginBottom: 0 }}>
                            🚨 <b>Se il tuo numero è superiore a 25</b> sarai inserito in <b>lista di attesa</b> e ti verrà successivamente comunicata l'eventuale iscrizione effettiva.
                        </p>

                        <Button variant='contained' color='primary' onClick={() => history.push('/courses/' + courseId + '/registration?town=' + paramTownLegalId + iframeSuffix)} style={{ display: 'block', margin: '28px auto 18px', padding: '10px 15px' }}>
                            Procedi con l'iscrizione
                        </Button>
                    </Paper>
                </div>
            )}

            {courseId === 'b4721094-e785-11ef-8676-bc2411550d1f' && (
                <div>
                    <Paper elevation={isIframe ? 0 : 3} style={{ padding: '8px', margin: width > 700 ? '6px 24px' : '2px', textAlign: 'center' }}>
                        <p style={{ fontSize: '1.3em', marginTop: '4px', marginBottom: 0 }}>
                            🤗 Ricordiamo che <b>l'evento è riservato <u>esclusivamente</u> agli associati</b> di educazione.sport <b>che:</b>
                        </p>
                        <p style={{ fontSize: '1.3em', margin: '0px auto', width: 'fit-content', textAlign: 'left' }}>
                            <ul>
                                <li>hanno effettuato un'iscrizione <b>ai camp estivi nell'Estate 2024</b> o</li>
                                <li>sono iscritti <b>ai corsi annuali 2024/2025.</b></li>
                            </ul>
                        </p>
                        <p style={{ fontSize: '1.3em', marginTop: '12px', marginBottom: 0 }}>
                            ➡️ <b>Il numero di iscrizioni massime</b> è di <b>25</b> partecipanti.
                        </p>
                        <p style={{ fontSize: '1.3em', marginTop: '18px', marginBottom: 0 }}>
                            📩 Al termine della compilazione <b>riceverai una mail</b> con il numero di posizione assegnato.
                        </p>
                        <p style={{ fontSize: '1.3em', marginTop: '18px', marginBottom: 0 }}>
                            🚨 <b>Se il tuo numero è superiore a 25</b> sarai inserito in <b>lista di attesa</b> e ti verrà successivamente comunicata l'eventuale iscrizione effettiva.
                        </p>

                        <Button variant='contained' color='primary' onClick={() => history.push('/courses/' + courseId + '/registration?town=' + paramTownLegalId + iframeSuffix)} style={{ display: 'block', margin: '28px auto 18px', padding: '10px 15px' }}>
                            Procedi con l'iscrizione
                        </Button>
                    </Paper>
                </div>
            )}
        </div>
    );
};

export default RegistrationLanding;
