import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Button } from '@material-ui/core';

import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import api from '../../../../common/utils/api';
import ExportDialog from './components/ExportDialog';
import RegistrationsTable from './components/RegistrationsTable/RegistrationsTable';
import { Title } from './styled';
import useSearchParams from '../../../../common/hooks/useSearchParams/useSearchParams';

const RegistrationLocationPage = () => {
    const { courseId, locationId } = useParams<{ courseId: string, locationId: string }>();
    const waitingList = !!(useSearchParams().waiting_list);
    const softDeleted = !!(useSearchParams().soft_deleted);

    const setIsLoading = useInternalLoader();
    const history = useHistory();

    const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);

    const [data, setData] = useState<any>([]);
    const [showSessions, setShowSessions] = useState(!!localStorage.getItem('admin_show_sessions'));

    useEffect(() => {
        localStorage.setItem('admin_show_sessions', showSessions ? 'true' : '');
    }, [showSessions]);

    const refreshRegistrations = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/courses/' + courseId + '/locations/' + locationId + '/registrations', 'GET', { waitingList, softDeleted }, true, false, true).then((res: any) => {
            setData(res);
        }).catch(() => {
            history.push((window.location.host.startsWith('admin.epuntos.it') ? '' : '/admin') + '/courses');
        }).finally(() => {
            setIsLoading(false);
        });
    }, [courseId, history, locationId, setIsLoading, waitingList, softDeleted]);

    useEffect(() => {
        refreshRegistrations();
    }, [refreshRegistrations]);

    const registrations = data?.registrations ?? [];
    const discounts = data?.discounts ?? [];

    const sessionsList = data?._raw?.registrations?.length ? Object.keys(data._raw.registrations[0].sessions) : [];

    const singleSessionEvent = (data.sessionType === 'single_event' && (sessionsList ?? []).length === 1);

    return (
        <>
            <div style={{ display: 'flex' }}>
                <Title isMobile={false}>
                    Iscrizioni {data?.courseName} <span style={{ fontSize: '0.7em' }}>{data?.locationName} {waitingList ? '(lista d\'attesa)' : (softDeleted ? '(iscrizioni cancellate)' : '')}</span>
                </Title>
                <div style={{ padding: '10px' }}>
                    <Button onClick={() => setShowSessions(s => !s)} color={'default'} variant={'contained'}>
                        {showSessions ? 'Nascondi' : 'Visualizza'} {singleSessionEvent ? 'adesioni' : (data.sessionType === 'daily_week_events' ? 'settimane' : (courseId === '42990ea4-76a0-11ef-aac5-4865ee14ab2a' ? 'pacchetti' : 'giorni'))}
                    </Button>{' '}
                    {!waitingList && !softDeleted && (
                        <Button onClick={() => setIsExportDialogOpen(true)} color={'primary'} variant={'contained'}>
                            Esporta iscrizioni
                        </Button>
                    )}
                </div>
            </div>

            <RegistrationsTable
                registrations={registrations}
                sessionsList={sessionsList}
                showSessions={showSessions}
                refreshRegistrations={refreshRegistrations}
                singleSessionEvent={singleSessionEvent}
                waitingList={waitingList}
                softDeleted={softDeleted}
                waGroupEnabled={data.waGroupEnabled}
            />

            <ExportDialog isOpen={isExportDialogOpen} onClose={() => setIsExportDialogOpen(false)} courseId={courseId} locationId={locationId} discounts={discounts} sessionType={data.sessionType} categorySlug={data?.courseCategory?.slug ?? ''} singleSessionEvent={singleSessionEvent} />
        </>
    );
};

export default RegistrationLocationPage;
