import { useCallback, useEffect, useState } from 'react';

import Button, { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import api from '../../../../../../common/utils/api';
import snackbar from '../../../../../../common/utils/snackbar';
import { Card, CardContent, Divider, Link, MenuItem, Typography } from '@material-ui/core';
import SelectStartDateDialog from '../SelectStartDateDialog/SelectStartDateDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { useMessageDialog } from '../../../../../../common/hooks/useMessageDialog/useMessageDialog';
import WageChangelogDialog from '../WageChangelogDialog/WageChangelogDialog';

interface Props {
    open: boolean;
    closeDialog: (shouldRefresh?: boolean) => void;
    selectedTrainer?: any;
    selectedTrainerId?: string;
    advancedWages?: boolean;
    externalSelectedCourseId?: string | null;
    externalSelectedCourseLocationId?: string | null;
}

const EditWageDialog = (props: Props) => {
    const { open, closeDialog, selectedTrainer, selectedTrainerId, advancedWages, externalSelectedCourseId, externalSelectedCourseLocationId } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [defaultWage, setDefaultWage] = useState<string | number>('0.00');
    const [costTimeUnit, setCostTimeUnit] = useState<string>('hourly');

    const [monthlyStartDate, setMonthlyStartDate] = useState('2024-09-01');
    const [monthlyEndDate, setMonthlyEndDate] = useState('2025-06-30');

    const [data, setData] = useState<any>({});

    const [isEditAdvancedWageDialogOpen, setIsEditAdvancedWageDialogOpen] = useState(false);
    const [isSelectStartDateDialogOpen, setIsSelectStartDateDialogOpen] = useState(false);
    const [isWageChangelogDialogOpen, setIsWageChangelogDialogOpen] = useState(false);

    const [courses, setCourses] = useState<any[]>([]);
    const [selectedCourseId, setSelectedCourseId] = useState<string | null>(null);
    const [selectedCourseLocationId, setSelectedCourseLocationId] = useState<string | null>(null);
    const [wageChangelogSelectedWage, setWageChangelogSelectedWage] = useState<any | undefined>(undefined);

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    useEffect(() => {
        if (!advancedWages) return;

        api.request('/admin/winter_staff/courses').then(res => {
            setCourses(res);
            console.log(res);
        })
    }, [advancedWages])

    useEffect(() => {
        if (!open) return;

        setData({});
        setDefaultWage('0.00');
        setMonthlyStartDate('2024-09-01');
        setMonthlyEndDate('2025-06-30');
        setCostTimeUnit('hourly');
        setSelectedCourseId(externalSelectedCourseId ?? null);
        setSelectedCourseLocationId(externalSelectedCourseLocationId ?? null);
    }, [open, externalSelectedCourseId, externalSelectedCourseLocationId])

    const refreshData = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/winter_staff/trainer_wages/' + selectedTrainerId).then(res => {
            const wageToUse = advancedWages ? (
                res.custom.current.find((w: any) => {
                    return w.course.id === externalSelectedCourseId && ((!selectedCourseLocationId && !w.courseLocation) || w.courseLocation?.id === externalSelectedCourseLocationId)
                })
            ) : res.default.current[0];

            if (wageToUse) {
                setDefaultWage(wageToUse.hourlyWage);
                setCostTimeUnit(wageToUse.costTimeUnit);
                setMonthlyStartDate(wageToUse.monthlyStartDate);
                setMonthlyEndDate(wageToUse.monthlyEndDate);
            }

            setData(res);
            setIsLoading(false);
        });
    }, [selectedTrainerId, advancedWages, externalSelectedCourseId, externalSelectedCourseLocationId, selectedCourseLocationId]);

    useEffect(() => {
        if (!selectedTrainerId || !open) return;

        refreshData();
    }, [open, selectedTrainerId, advancedWages, externalSelectedCourseId, externalSelectedCourseLocationId, refreshData]);

    const saveData = useCallback((startDate: string | null) => {
        setIsLoading(true);
        api.request('/admin/winter_staff/trainer_wages/' + selectedTrainerId, 'POST', { wage: defaultWage, startDate, courseId: selectedCourseId, courseLocationId: selectedCourseLocationId, costTimeUnit, monthlyStartDate, monthlyEndDate }).then(() => {
            snackbar.success('Compenso orario salvato con successo!');
            closeDialog(true);
            setIsSelectStartDateDialogOpen(false);
        }).catch(() => {
            //snackbar.error('Si è verificato un errore durante il salvataggio.');
        }).finally(() => {
            setIsLoading(false);
        });
    }, [defaultWage, closeDialog, selectedTrainerId, selectedCourseId, selectedCourseLocationId, costTimeUnit, monthlyStartDate, monthlyEndDate]);

    const simulateSaveData = useCallback(() => {
        setIsLoading(true);
        api.request('/admin/winter_staff/trainer_wages/' + selectedTrainerId, 'POST', { wage: defaultWage, courseId: selectedCourseId, courseLocationId: selectedCourseLocationId, costTimeUnit, monthlyStartDate, monthlyEndDate, simulate: true }).then((res: any) => {
            if (res.wageExists) {
                setIsSelectStartDateDialogOpen(true);
            } else {
                saveData(null);
            }
        }).catch(() => {
            //snackbar.error('Si è verificato un errore durante il salvataggio.');
        }).finally(() => {
            setIsLoading(false);
        });
    }, [defaultWage, selectedTrainerId, saveData, selectedCourseId, selectedCourseLocationId, costTimeUnit, monthlyStartDate, monthlyEndDate]);

    const deleteCustomWage = useCallback((customWageId: string) => {
        showMessageDialog({
            title: 'Elimina compenso diverso',
            message: (
                <>
                    <p style={{ margin: '0px' }}>
                        Sei sicuro di volere eliminare il compenso selezionato?
                    </p>
                </>
            ),
            actions: [
                {
                    text: 'Annulla',
                    action: () => {
                        closeMessageDialog();
                    }
                },
                {
                    text: 'Conferma',
                    action: () => {
                        closeMessageDialog();

                        api.request('/admin/winter_staff/trainer_wages/' + selectedTrainerId + '/wages/' + customWageId, 'DELETE').then(() => {
                            snackbar.success('Compenso eliminato con successo!');
                            refreshData();
                        });
                    }
                }
            ]
        });
    }, [closeMessageDialog, showMessageDialog, selectedTrainerId, refreshData]);

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => closeDialog()
        },
        ...(advancedWages ? [{
            children: 'Salva',
            color: 'primary',
            onClick: () => simulateSaveData(),
            disabled: advancedWages && !selectedCourseId
        } as ButtonProps] : [])
    ];

    return (
        <CustomDialogWrapper open={open} onClose={() => closeDialog()} title={'Compensi ' + selectedTrainer?.lastName + ' ' + selectedTrainer?.firstName} buttons={buttons} isLoading={isLoading} maxWidth='xs' fullWidth>
            {advancedWages && (
                <>
                    <CustomTextField
                        label={'Corso'}
                        value={selectedCourseId}
                        onChange={(e: any) => {
                            setSelectedCourseId(e.target.value);
                            setSelectedCourseLocationId(null);
                        }}
                        variant='outlined'
                        required
                        fullWidth
                        select
                        disabled={!!externalSelectedCourseId || !!externalSelectedCourseLocationId}
                    >
                        {courses.map((opt: any) => (
                            <MenuItem key={opt.id} value={opt.id} style={{ whiteSpace: 'break-spaces' }}>
                                {opt.name}
                            </MenuItem>
                        ))}
                    </CustomTextField>

                    {(courses.find(c => c.id === selectedCourseId)?.categorySlug !== 'school_courses') && (
                        <CustomTextField
                            label={'Paese'}
                            value={(selectedCourseLocationId === null && selectedCourseId) ? 'NULL' : selectedCourseLocationId}
                            onChange={(e: any) => {
                                setSelectedCourseLocationId(e.target.value);
                            }}
                            variant='outlined'
                            fullWidth
                            select
                            disabled={!!externalSelectedCourseId || !!externalSelectedCourseLocationId}
                        >
                            {selectedCourseId ? (
                                <MenuItem value={'NULL'} style={{ whiteSpace: 'break-spaces' }}>
                                    Tutti i paesi
                                </MenuItem>
                            ) : (
                                <MenuItem value={'***'} disabled style={{ whiteSpace: 'break-spaces' }}>
                                    Seleziona prima un corso
                                </MenuItem>
                            )}

                            {(courses.find(c => c.id === selectedCourseId)?.locations ?? []).map((opt: any) => (
                                <MenuItem key={opt.id} value={opt.id} style={{ whiteSpace: 'break-spaces' }}>
                                    {opt.name}
                                </MenuItem>
                            ))}
                        </CustomTextField>
                    )}

                    <div style={{ display: 'flex' }}>
                        <CustomTextField
                            label={'Tipologia costo'}
                            value={costTimeUnit}
                            onChange={(e: any) => {
                                setCostTimeUnit(e.target.value);
                            }}
                            variant='outlined'
                            fullWidth
                            select
                            disabled={!!externalSelectedCourseId || !!externalSelectedCourseLocationId}
                        >
                            <MenuItem value={'hourly'} style={{ whiteSpace: 'break-spaces' }}>
                                orario
                            </MenuItem>
                            <MenuItem value={'lesson'} style={{ whiteSpace: 'break-spaces' }}>
                                a lezione
                            </MenuItem>
                            <MenuItem value={'monthly'} style={{ whiteSpace: 'break-spaces' }}>
                                mensile
                            </MenuItem>
                        </CustomTextField>
                    </div>
                </>
            )}

            {costTimeUnit === 'monthly' && (
                <div style={{ display: 'flex' }}>
                    <CustomTextField
                        label={'Mese inizio'}
                        value={monthlyStartDate}
                        onChange={(e: any) => {
                            setMonthlyStartDate(e.target.value);
                        }}
                        variant='outlined'
                        fullWidth
                        select
                        disabled={!!externalSelectedCourseId || !!externalSelectedCourseLocationId}
                        style={{ marginRight: '4px' }}
                    >
                        <MenuItem value={'2024-09-01'} style={{ whiteSpace: 'break-spaces' }}>
                            settembre 2024
                        </MenuItem>
                        <MenuItem value={'2024-10-01'} style={{ whiteSpace: 'break-spaces' }}>
                            ottobre 2024
                        </MenuItem>
                        <MenuItem value={'2024-11-01'} style={{ whiteSpace: 'break-spaces' }}>
                            novembre 2024
                        </MenuItem>
                        <MenuItem value={'2024-12-01'} style={{ whiteSpace: 'break-spaces' }}>
                            dicembre 2025
                        </MenuItem>
                        <MenuItem value={'2025-01-01'} style={{ whiteSpace: 'break-spaces' }}>
                            gennaio 2025
                        </MenuItem>
                        <MenuItem value={'2025-02-01'} style={{ whiteSpace: 'break-spaces' }}>
                            febbraio 2025
                        </MenuItem>
                        <MenuItem value={'2025-03-01'} style={{ whiteSpace: 'break-spaces' }}>
                            marzo 2025
                        </MenuItem>
                        <MenuItem value={'2025-04-01'} style={{ whiteSpace: 'break-spaces' }}>
                            aprile 2025
                        </MenuItem>
                        <MenuItem value={'2025-05-01'} style={{ whiteSpace: 'break-spaces' }}>
                            maggio 2025
                        </MenuItem>
                        <MenuItem value={'2025-06-01'} style={{ whiteSpace: 'break-spaces' }}>
                            giugno 2025
                        </MenuItem>
                    </CustomTextField>

                    <CustomTextField
                        label={'Mese fine'}
                        value={monthlyEndDate}
                        onChange={(e: any) => {
                            setMonthlyEndDate(e.target.value);
                        }}
                        variant='outlined'
                        fullWidth
                        select
                        disabled={!!externalSelectedCourseId || !!externalSelectedCourseLocationId}
                        style={{ marginLeft: '4px' }}
                    >
                        <MenuItem value={'2024-09-30'} style={{ whiteSpace: 'break-spaces' }}>
                            settembre 2024
                        </MenuItem>
                        <MenuItem value={'2024-10-31'} style={{ whiteSpace: 'break-spaces' }}>
                            ottobre 2024
                        </MenuItem>
                        <MenuItem value={'2024-11-30'} style={{ whiteSpace: 'break-spaces' }}>
                            novembre 2024
                        </MenuItem>
                        <MenuItem value={'2024-12-31'} style={{ whiteSpace: 'break-spaces' }}>
                            dicembre 2025
                        </MenuItem>
                        <MenuItem value={'2025-01-31'} style={{ whiteSpace: 'break-spaces' }}>
                            gennaio 2025
                        </MenuItem>
                        <MenuItem value={'2025-02-28'} style={{ whiteSpace: 'break-spaces' }}>
                            febbraio 2025
                        </MenuItem>
                        <MenuItem value={'2025-03-31'} style={{ whiteSpace: 'break-spaces' }}>
                            marzo 2025
                        </MenuItem>
                        <MenuItem value={'2025-04-30'} style={{ whiteSpace: 'break-spaces' }}>
                            aprile 2025
                        </MenuItem>
                        <MenuItem value={'2025-05-31'} style={{ whiteSpace: 'break-spaces' }}>
                            maggio 2025
                        </MenuItem>
                        <MenuItem value={'2025-06-30'} style={{ whiteSpace: 'break-spaces' }}>
                            giugno 2025
                        </MenuItem>
                    </CustomTextField>
                </div>
            )}

            <div style={{ display: 'flex' }}>
                <CustomTextField
                    label={'Compenso ' + (costTimeUnit === 'hourly' ? 'orario' : '') + (advancedWages ? '' : ' predefinito')}
                    variant='outlined'
                    value={defaultWage}
                    keepMounted={true}
                    onChange={(e: any) => {
                        setDefaultWage(e.target.value);
                    }}
                    onBlur={() => {
                        setDefaultWage((c: any) => {
                            return Math.max(((typeof c === 'string') ? parseFloat(c.replaceAll(',', '.')) : c), 0).toFixed(2);
                        });
                    }}
                    disabled={isLoading}
                />

                {!advancedWages && (
                    <div>
                        <Button onClick={() => simulateSaveData()} color={'primary'} variant={'contained'} style={{ marginTop: '10px', marginLeft: '10px' }}>
                            Salva
                        </Button>
                    </div>
                )}
            </div>

            {!advancedWages && (
                <>
                    {(data?.default?.previous ?? []).length > 0 && (
                        <div style={{ flex: 1, textAlign: 'right', display: 'flex', alignItems: 'center', marginTop: '2px', marginBottom: '12px' }}>
                            <div style={{ flex: 1 }}>
                                <Link href='#' onClick={(e: any) => {
                                    e.preventDefault();
                                    setIsWageChangelogDialogOpen(true);
                                    setWageChangelogSelectedWage(data?.default?.current);
                                }}>
                                    Storico compensi
                                </Link>
                            </div>
                        </div>
                    )}

                    <Divider />

                    <Typography variant='h5' style={{ margin: '14px 0 8px', fontSize: '1.4em', flex: 1 }}>
                        Compensi diversi
                    </Typography>

                    {(data?.custom?.current ?? []).map((customWage: any) => {
                        return (
                            <Card variant='outlined' style={{ marginBottom: '12px' }}>
                                <CardContent>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ flex: 1, display: 'flex', alignItems: 'center', fontSize: '1.75em' }}>
                                                {parseFloat(customWage.hourlyWage)} €/{customWage.costTimeUnit === 'hourly' ? 'ora' : (customWage.costTimeUnit === 'lesson' ? 'lezione' : 'mese')}
                                            </div>
                                            <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                                                {customWage.course.name + ' ' + (customWage.courseLocation ? (customWage.courseLocation.townName + ' ') : '')}<br />
                                                {(customWage.course.season.name.toLowerCase().includes('invernale') ? (customWage.course.season.year + '/' + (customWage.course.season.year + 1)) : (customWage.course.season.year + 1))}
                                            </div>
                                            {customWage.costTimeUnit === 'monthly' && (
                                                <>
                                                    {moment(customWage.monthlyStartDate).format('MMMM')} - {moment(customWage.monthlyEndDate).format('MMMM')}
                                                </>
                                            )}
                                            {customWage.startDate !== '1970-01-01' && (
                                                <div style={{ flex: 1, display: 'flex', alignItems: 'center', paddingTop: '6px', fontSize: '0.9em' }}>
                                                    (dal {moment(customWage.startDate).format('DD/MM/YYYY')})
                                                </div>
                                            )}
                                        </div>
                                        <div style={{ flexGrow: 1 }} />
                                        <div style={{ textAlign: 'right', alignItems: 'center', display: 'flex' }}>
                                            <Button onClick={() => {
                                                setSelectedCourseId(customWage.course.id);
                                                setSelectedCourseLocationId(customWage.courseLocation?.id ?? null);
                                                setIsEditAdvancedWageDialogOpen(true);
                                            }} color={'primary'} variant={'outlined'} size='small' style={{ minWidth: '32px', marginRight: '8px' }}>
                                                <EditIcon />
                                            </Button>
                                            <Button onClick={() => deleteCustomWage(customWage.id)} color={'secondary'} variant={'outlined'} size='small' style={{ minWidth: '32px' }}>
                                                <DeleteIcon />
                                            </Button>
                                        </div>
                                    </div>

                                    {(data?.custom?.previous ?? []).filter((w: any) => w.course?.id === customWage.course?.id && w.courseLocation?.id === customWage.courseLocation?.id).length > 0 && (
                                        <div style={{ flex: 1, textAlign: 'right', display: 'flex', alignItems: 'center', margin: '0' }}>
                                            <div style={{ flex: 1 }}>
                                                <Link href='#' onClick={(e: any) => {
                                                    e.preventDefault();
                                                    setIsWageChangelogDialogOpen(true);
                                                    setWageChangelogSelectedWage(customWage);
                                                }}>
                                                    Storico compensi
                                                </Link>
                                            </div>
                                        </div>
                                    )}
                                </CardContent>
                            </Card>
                        )
                    })}

                    {(data?.custom?.current ?? []).length === 0 && (
                        <div style={{ textAlign: 'center', color: '#464646', fontSize: '1.25em', margin: '16px 4px 18px' }}>
                            Nessun compenso diverso presente
                        </div>
                    )}

                    <Button onClick={() => {
                        setSelectedCourseId(null);
                        setSelectedCourseLocationId(null);
                        setIsEditAdvancedWageDialogOpen(true);
                    }} color={'primary'} variant={'outlined'} style={{ display: 'block', margin: '0 auto' }}>
                        Aggiungi compenso
                    </Button>

                    <EditWageDialog
                        open={isEditAdvancedWageDialogOpen}
                        closeDialog={(shouldRefresh?: boolean) => {
                            setIsEditAdvancedWageDialogOpen(false)
                            if (shouldRefresh) refreshData();
                        }}
                        selectedTrainer={selectedTrainer}
                        selectedTrainerId={selectedTrainerId}
                        advancedWages
                        externalSelectedCourseId={selectedCourseId}
                        externalSelectedCourseLocationId={selectedCourseLocationId}
                    />
                </>
            )}

            <SelectStartDateDialog
                open={isSelectStartDateDialogOpen}
                saveData={(date: string) => saveData(date)}
                isLoading={isLoading}
            />

            <WageChangelogDialog
                open={isWageChangelogDialogOpen}
                closeDialog={() => setIsWageChangelogDialogOpen(false)}
                selectedTrainer={selectedTrainer}
                selectedWage={wageChangelogSelectedWage}
                data={data}
            />
        </CustomDialogWrapper>
    );
};

export default EditWageDialog;
