import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';

import GenericObject from '../../../../../../../../typesAdditional/GenericObject';
import { TextField } from '@material-ui/core';

interface Props {
    discounts: GenericObject;
    data: GenericObject;
    rawOnChange: (field: string, value: any) => void;
}

const DiscountSelector = (props: Props) => {
    const { discounts, data, rawOnChange } = props;

    const tenarisEmployeeCode = data?._additionals?.tenarisEmployeeCode ?? '';
    const selectedDiscount = data?.discount_id ?? '';

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        rawOnChange('discount_id', value);
    };

    const customDiscounts = discounts?.list?.filter((discount: GenericObject) => {
        return discount.conditionType === 'custom';
    }) ?? [];

    if (customDiscounts.length === 0) {
        return null;
    }

    return (
        <div style={{ marginBottom: '20px' }}>
            {customDiscounts.length === 1 && customDiscounts[0].id === '18335161-c387-4785-8630-ae58ab7ba99b' ? (
                <>
                    <Typography variant='h4' style={{ fontSize: '1.4em', textAlign: 'center', margin: '8px', marginBottom: '10px' }}>
                        Il partecipante al Camp è un alunno/a dell'Istituto Scolastico Vest di Clusone?
                    </Typography>

                    <FormControl component='fieldset'>
                        <RadioGroup aria-label='gender' name='gender1' value={selectedDiscount} onChange={handleChange}>
                            {customDiscounts.map((discount: any) => {
                                return (
                                    <FormControlLabel value={discount.id} control={<Radio color='primary' />} label={'Sì'} />
                                );
                            })}
                            <FormControlLabel value='' control={<Radio color='primary' />} label='No' />
                        </RadioGroup>
                    </FormControl>
                </>
            ) : (
                <>
                    {customDiscounts.length === 1 && customDiscounts[0].description.includes('Tenaris') ? (
                        (
                            <>
                                <Typography variant='h4' style={{ fontSize: '1.4em', textAlign: 'center', margin: '8px', marginBottom: '10px' }}>
                                    Sei dipendente Tenaris?
                                </Typography>

                                <FormControl component='fieldset'>
                                    <RadioGroup aria-label='gender' name='gender1' value={selectedDiscount} onChange={handleChange}>
                                        <FormControlLabel value='' control={<Radio color='primary' />} label='No' />

                                        {customDiscounts.map((discount: any) => {
                                            return (
                                                <FormControlLabel value={discount.id} control={<Radio color='primary' />} label={'Sì'} />
                                            );
                                        })}
                                    </RadioGroup>
                                </FormControl>


                                {selectedDiscount === customDiscounts[0].id && (
                                    <TextField
                                        label='Codice Dipendente'
                                        variant='outlined'
                                        value={tenarisEmployeeCode}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => rawOnChange('_additionals.tenarisEmployeeCode', e.target.value.replace(/[\W_]+/g, "").toUpperCase())}
                                        style={{ flex: 1, margin: '16px 6px' }}
                                        inputProps={{ maxLength: 50 }}
                                        fullWidth
                                    //error={!(tenarisEmployeeCode.trim())}
                                    />
                                )}

                            </>
                        )
                    ) : (
                        <>
                            <Typography variant='h4' style={{ fontSize: '1.4em', textAlign: 'center', margin: '8px', marginBottom: '10px' }}>
                                Se vuoi usufruire di una convenzione, selezionala qui
                            </Typography>
                            <FormControl component='fieldset'>
                                <RadioGroup aria-label='gender' name='gender1' value={selectedDiscount} onChange={handleChange}>
                                    <FormControlLabel value='' control={<Radio color='primary' />} label='Nessuna convenzione' />
                                    {customDiscounts.map((discount: any) => {
                                        return (
                                            <FormControlLabel value={discount.id} control={<Radio color='primary' />} label={discount.description} />
                                        );
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </>
                    )}
                </>
            )}

        </div>
    );
};

export default DiscountSelector;
