import { useCallback, useState } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from '@material-ui/lab';

import api from '../../../../../../common/utils/api';
import LoadingSimulationAlert from '../../components/LoadingSimulationAlert/LoadingSimulationAlert';
import SimulationRecapAlert from '../../components/SimulationRecapAlert/SimulationRecapAlert';
import StepFooter from '../../components/StepFooter/StepFooter';
import useSimulateRegistration from '../../hooks/useSimulateRegistration/useSimulateRegistration';
import { GenericStepProps } from '../types';
import GenericObject from '../../../../../../typesAdditional/GenericObject';

interface Props extends GenericStepProps {
    courseData: any;
    setSelectedLocationId: (locationId: string) => void;
    setSelectedSessions: (sessions: any) => void;
    suggestedSessions: GenericObject[];
    setSuggestedSessions: (sessions: any) => void;
    initialSelectedLocationId: string;
    initialSelectedSessions: any[];
    questionResponse: string;
    setQuestionResponse: (res: string) => void;
}

const RegistrationStepVoucher = (props: Props) => {
    const { courseId, courseData, data, prevStep, nextStep, rawOnChange, initialSelectedSessions, initialSelectedLocationId, suggestedSessions } = props;
    const { sessionType } = courseData;

    const [selectedLocationId, setSelectedLocationId] = useState(initialSelectedLocationId);

    const [isLoading, setIsLoading] = useState(false);
    const [voucherCode, setVoucherCode] = useState(data?.voucher_code ?? '');
    const [validationResult, setValidationResult] = useState<any | null>(null);

    const {
        simulateRegistrationData,
        getSessionsLabel,
        countSessions,
        countSessionsFoodService,
        countQueueSessions,
        isLoading: isSimulationLoading,
        toPay,
        saving,
        additionalSaving
    } = useSimulateRegistration({ courseId, courseData, data, sessionType, selectedLocationId, setSelectedLocationId, initialSelectedSessions, suggestedSessions });

    const isLoadingReal = isLoading || isSimulationLoading;

    const internalPrevStep = useCallback(() => {
        if (prevStep) {
            prevStep();
        }
    }, [prevStep]);

    const internalNextStep = useCallback(() => {
        if (voucherCode) {
            rawOnChange('voucher_code', voucherCode);
        }

        if (nextStep) {
            nextStep();
        }
    }, [rawOnChange, voucherCode, nextStep]);

    const validateVoucher = useCallback(() => {
        setIsLoading(true);
        rawOnChange('voucher_code', voucherCode);
        api.request('/courses/validate_voucher', 'POST', { code: voucherCode, courseId, personId: data?.participant?.id ?? '' }).then(res => {
            setValidationResult(res);
        }).catch(() => {
            setValidationResult(null);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [voucherCode, setValidationResult, rawOnChange, courseId, data]);

    const deleteVoucherAndNextStep = useCallback(() => {
        rawOnChange('voucher_code', '');

        if (nextStep) {
            nextStep();
        }
    }, [rawOnChange, nextStep]);

    const realSessions = simulateRegistrationData?.discounts?.selected?.customPricing?.sessions ?? simulateRegistrationData?.sessions ?? [];

    const pricesNotAvailable = realSessions?.some((session: any) => {
        return session?.prices?.course?.actual?.price <= 0;
    }) && courseId !== '7030abec-7234-4939-a154-336743ed9f92' && courseId !== 'b4721094-e785-11ef-8676-bc2411550d1f';

    return (
        <>
            <div>
                <Typography variant='h4' style={{ fontSize: '1.4em', textAlign: 'center', margin: '8px', marginBottom: '10px' }}>
                    Se hai un codice sconto, inseriscilo qui
                </Typography>

                <div style={{ textAlign: 'center' }}>
                    <TextField
                        style={{ margin: '12px auto 0' }}
                        label='Codice sconto'
                        variant='outlined'
                        value={(simulateRegistrationData?.savedVoucher ?? '') ? (simulateRegistrationData?.savedVoucher) : voucherCode}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setVoucherCode(e.target.value);
                            setValidationResult(null);
                        }}
                        inputProps={{ readOnly: !!(simulateRegistrationData?.savedVoucher ?? '') }}
                    />

                    <div style={{ marginBottom: '16px' }}>
                        {!(simulateRegistrationData?.savedVoucher ?? '') && (
                            <div style={{ marginBottom: '16px' }}>
                                <Button onClick={() => validateVoucher()} color={'primary'} variant={'contained'} disabled={isLoadingReal} style={{ display: 'block', margin: '12px auto' }}>
                                    Applica codice
                                </Button>

                                <Button onClick={() => deleteVoucherAndNextStep()} color={'primary'} variant={'outlined'} disabled={isLoadingReal} style={{ display: 'block', margin: '0px auto 12px' }}>
                                    Non ho un codice sconto
                                </Button>
                            </div>
                        )}

                        {validationResult && (
                            <Alert severity={validationResult.valid ? 'success' : 'error'} style={{ paddingBottom: '0px', textAlign: 'left' }}>
                                <AlertTitle>Codice sconto {validationResult.valid ? 'valido' : 'non valido'}</AlertTitle>
                                {validationResult.valid && (
                                    <div style={{ textAlign: 'left', marginBottom: '4px' }}>
                                        Valore: €<b>{parseFloat(validationResult.amount).toFixed(2)}</b>
                                    </div>
                                )}
                                {!validationResult.valid && validationResult.validationDesc && (
                                    <div style={{ textAlign: 'left', marginBottom: '4px' }}>
                                        {validationResult.validationDesc}
                                    </div>
                                )}
                            </Alert>
                        )}
                    </div>

                </div>

                {/*
                <Divider style={{margin: '16px 0'}} />

                <div style={{fontSize: '0.9em', marginBottom: '16px'}}>
                    Spiegazione su come ottenere codici sconto. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam condimentum eu mi id pulvinar. Integer semper nisl vitae dolor pretium, nec volutpat purus pharetra. In hac habitasse platea dictumst. Integer at facilisis justo. Curabitur varius turpis leo, vel fermentum turpis molestie at. Praesent pretium nunc ac placerat vehicula. In mi metus, egestas quis ex ac, venenatis posuere purus. Vestibulum et ultricies metus. In finibus ornare viverra. Ut eget leo fermentum, suscipit lorem a, euismod leo. Praesent ultricies ligula volutpat feugiat suscipit. Suspendisse a velit bibendum, volutpat ipsum accumsan, interdum sem. Nulla tempor, dui eget lobortis lobortis, dolor augue consectetur risus, ac congue est mi non augue. In a sem accumsan, scelerisque quam a, cursus eros. Sed blandit, libero eu consectetur condimentum, urna tellus vestibulum enim, id malesuada nisl justo quis erat.
                </div>
                */}
            </div>

            <div>
                {isLoadingReal ? (
                    <LoadingSimulationAlert />
                ) : (
                    <>
                        {(countSessions + countQueueSessions) > 0 && (
                            <SimulationRecapAlert
                                simulateRegistrationData={simulateRegistrationData}
                                countSessions={countSessions}
                                countSessionsFoodService={countSessionsFoodService}
                                countQueueSessions={countQueueSessions}
                                toPay={toPay}
                                saving={saving}
                                additionalSaving={additionalSaving}
                                getSessionsLabel={getSessionsLabel}
                                pricesNotAvailable={pricesNotAvailable}
                            />
                        )}
                    </>
                )}

            </div>

            <StepFooter isLoading={isLoadingReal} prevStep={internalPrevStep} nextStep={internalNextStep} />
        </>
    );
};

export default RegistrationStepVoucher;
